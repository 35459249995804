import { timeRemaining } from '../helpers';

import { DRAFT, IN_PROGRESS, JUDGING, JUDGED, PRE_REGISTRATION } from '../constants';
import { CONTEST_DATE_AT_TIME_FORMAT, CONTEST_DATE_FORMAT, formatContestTimestampPT } from '../../../utility/time';

const dateFormatters = { winners_announced_date: CONTEST_DATE_FORMAT };

const getFormatter = (eventName) => dateFormatters[eventName] || CONTEST_DATE_AT_TIME_FORMAT;

const eventHeaders = {
  end_date: 'Submissions close',
  free_hardware_end_date: 'Applications for hardware close',
  free_hardware_winners_announced_date: 'Hardware recipients announced',
  start_date: 'Contest begins',
  winners_announced_date: 'Winners announced by',
};

export function formatEvents(challenge) {
  const challengeEventNames = Object.keys(challenge).filter((key) => !!eventHeaders[key]);
  const nowMs = Date.now();

  if (challengeEventNames.length > 0) {
    const challengeEvents = challengeEventNames.reduce((acc, eventName) => {
      const isoDate = challenge[eventName];
      if (!isoDate) return acc;

      const ms = Date.parse(isoDate);

      return [
        ...acc,
        {
          date: formatContestTimestampPT(isoDate, getFormatter(eventName)),
          headerText: eventHeaders[eventName],
          isPast: nowMs > ms,
          ms,
          name: eventName,
        },
      ];
    }, []).sort((eventA, eventB) => eventA.ms - eventB.ms);

    return { challengeEvents, nowMs };
  }

  return { challengeEvents: [], nowMs };
}

export function formatStatus({ ready, status, start_date, end_date, winners_announced_date }) {
  const showWinnersAnnouncedDate = winners_announced_date && Date.parse(winners_announced_date) > Date.now();

  // Sorted by timeline order
  switch (status) {
    case DRAFT:
      // TODO: Refactor this. Disabling for linter clean-up
      // eslint-disable-next-line no-case-declarations
      const text = ready
        ? `Launch scheduled for ${formatContestTimestampPT(start_date, CONTEST_DATE_AT_TIME_FORMAT)}`
        : 'Please mark the challenge as "ready to launch" when ready.';

      return {
        status,
        text: [text],
      };
    case PRE_REGISTRATION:
      return {
        date: formatContestTimestampPT(start_date, CONTEST_DATE_AT_TIME_FORMAT),
        status,
        text: ['Project submissions open in', 'Opens on'],
        timeRemaining: timeRemaining(start_date),
      };
    case IN_PROGRESS:
      return {
        date: formatContestTimestampPT(end_date, CONTEST_DATE_AT_TIME_FORMAT),
        status,
        text: ['Submissions close in', 'Ends on'],
        timeRemaining: timeRemaining(end_date),
      };
    case JUDGING:
      if (showWinnersAnnouncedDate) {
        return {
          date: formatContestTimestampPT(winners_announced_date, CONTEST_DATE_FORMAT),
          status,
          text: ['Winners will be announced by'],
        };
      }

      return {
        status,
        text: ['Winners will be announced soon.'],
      };
    case JUDGED:
      return {
        status,
        text: ['🎉 Prizes awarded! 🎉'],
      };
    default:
      return {
        status: null,
        text: ['Contest status is currently unavailable.'],
      };
  }
}
