import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import AnalyticsLink from '../../../client/link/AnalyticsLink';
import ContentPageAd from '../../../client/ads/content_page/ContentPageAd';
import NewsPageHeroAd from '../../../client/ads/news_page_hero/NewsPageHeroAd';
import NewsSignupCTA from '../shared/signup_cta';

import { NEWS_PROMO_BANNER } from '../../../services/keen/events/linkConstants';

import newsPageHeroAdStyles from '../../../client/ads/news_page_hero/news_page_hero.css';
import utilStyles from '../../../styles/global_ui/util.css';
import styles from './news_home_page.css';

class AdsSection extends PureComponent {
  constructor(props) {
    super(props);
  }

  /**
   * Views
   */
  _getAdView() {
    if (!this.props.adsInitialized) return this._getPlaceholderView();

    if (this.props.ads.hero) {
      return this._getNewsPageHeroAdView();
    } else if (this.props.ads.content && this.props.ads.content.length > 0) {
      return this._getContentAdsWrapper();
    } else {
      return this._getFallbackView();
    }
  }

  _getContentAdsWrapper() {
    return (
      <div className={styles.stackedContentAdsWrapper}>
        {this.props.ads.content.map((ad) => (
          <ContentPageAd
            key={ad.id}
            ad={ad}
            initialized={this.props.adsInitialized}
            onClick={this.handleClick}
            pageType="news_home_page"
          />
        ))}
      </div>
    );
  }

  _getFallbackView() {
    const { ad_urls } = this.props;

    return (
      <AnalyticsLink
        className={styles.banner}
        isExternal={ad_urls.banner.external}
        item={{ url: ad_urls.banner.href }}
        linkType={NEWS_PROMO_BANNER}
        value={ad_urls.banner.alt_text}
      >
        <picture>
          <source media="(max-width: 768px)" srcSet={ad_urls.banner.mobile_url} />
          <img
            alt={ad_urls.banner.alt_text}
            className={utilStyles.borderRadius}
            src={ad_urls.banner.url}
          />
        </picture>
      </AnalyticsLink>
    );
  }

  _getNewsPageHeroAdView() {
    return (
      <NewsPageHeroAd
        ad={this.props.ads.hero}
        initialized={this.props.adsInitialized}
        onClick={this.handleClick}
      />
    );
  }

  _getPlaceholderView() {
    return (<div className={`${newsPageHeroAdStyles.placeholder} ${utilStyles.bgFog} ${utilStyles.borderRadius}`} />);
  }

  render() {
    return (
      <div className={styles.ctaSection}>
        {this._getAdView()}
        <NewsSignupCTA className={styles.cta} imageURLs={this.props.signup_cta_image_urls} />
      </div>
    );
  }
}

AdsSection.propTypes = {
  ad_urls: PropTypes.shape({
    banner: PropTypes.shape({
      href: PropTypes.string.isRequired,
      mobile_url: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  ads: PropTypes.object,
  adsInitialized: PropTypes.bool.isRequired,
  signup_cta_image_urls: PropTypes.object.isRequired,
};

AdsSection.defaultProps = { ads: null };

export default AdsSection;
