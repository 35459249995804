import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';

import LazyImage from '../../../../client/reusable_components/LazyImage';
import SoftArrow from '../../../../client/reusable_components/icons/SoftArrow';

import layout from '../../../../styles/global_ui/layout.css';
import slideDown from '../../../../styles/transitions/slide_down.css';
import styles from './list.css';

class ExpandableCard extends Component {
  constructor(props) {
    super(props);
    this.toggleExpanded = this.toggleExpanded.bind(this);
    this.state = { expanded: false };
  }

  componentDidUpdate(prevProps) {
    if (this.state.expanded && this.props.expandedCard !== prevProps.uuid) {
      this.setState({ expanded: false });
    }
  }

  toggleExpanded() {
    this.props.expanding(this.props.uuid, !this.state.expanded);
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const { classList, dropdown, icon, info, isExpandable, isNestedInDialog, link, onMouseLeave, onMouseOver, subtitle, title } = this.props;

    return (
      <div className={styles.card} onMouseLeave={onMouseLeave}>
        <div className={styles.cardInner}>
          <div className={styles.textSection}>
            {icon && icon.length > 0
            && (
              <a className={`${styles.icon} ${classList.icon}`} href={link}>
                <LazyImage
                  captureScroll={isNestedInDialog}
                  className={layout.flexCenterItems}
                  ratio="1:1"
                  src={icon}
                  width={36}
                />
              </a>
            )}
            <div>
              <a
                className={styles.title}
                href={link}
                onMouseOver={onMouseOver}
              >
                {title}
              </a>
              {subtitle && subtitle.length > 0 && <div className={styles.subtitle}>{subtitle}</div>}
              {info && info.length > 0 && <div className={styles.info}>{info}</div>}
            </div>
          </div>

          <div className={styles.childrenSection}>
            {this.props.children}
            {isExpandable
            && (
              <span className={styles.arrowWrapper} onClick={this.toggleExpanded}>
                <SoftArrow className={styles.arrow} direction={this.state.expanded ? 'up' : 'down'} height="10" width="10" />
              </span>
            )}
          </div>
        </div>
        <CSSTransition
          classNames={slideDown}
          in={this.state.expanded && !!dropdown}
          mountOnEnter={true}
          timeout={450}
          unmountOnExit={true}
        >
          <div className={styles.dropdown}>{dropdown}</div>
        </CSSTransition>
      </div>
    );
  }
}

ExpandableCard.propTypes = {
  classList: PropTypes.shape({ icon: PropTypes.string }),
  dropdown: PropTypes.element,
  expandedCard: PropTypes.string,
  expanding: PropTypes.func,
  icon: PropTypes.string,
  info: PropTypes.string,
  isExpandable: PropTypes.bool,
  isNestedInDialog: PropTypes.bool,
  link: PropTypes.string,
  onMouseLeave: PropTypes.func,
  onMouseOver: PropTypes.func,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  uuid: PropTypes.string,
};

ExpandableCard.defaultProps = {
  classList: { icon: '' },
  dropdown: null,
  expandedCard: null,
  expanding: () => {},
  icon: '',
  info: '',
  isExpandable: false,
  isNestedInDialog: false,
  link: 'javascript:void(0);',
  onMouseLeave: () => {},
  onMouseOver: () => {},
  subtitle: '',
  uuid: null,
};

export default ExpandableCard;
