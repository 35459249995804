import React from 'react';
import PropTypes from 'prop-types';

import ActiveContestCard from '../../client/cards/contest_card/ActiveContestCard';

import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';

const ActiveContestsSection = ({ communityContests, partnerContests }) => (
  <div>
    {partnerContests.length > 0
    && (
      <div className={layout.marginBottom45}>
        <h3 className={`${layout.marginBottom22} ${typography.h3}`}>Partner</h3>
        {partnerContests.map((contest) => (
          <ActiveContestCard key={contest.id} contest={contest} />
        ))}
      </div>
    )}

    {communityContests.length > 0
    && (
      <div>
        <h3 className={`${layout.marginBottom22} ${typography.h3}`}>Community</h3>
        {communityContests.map((contest) => (
          <ActiveContestCard key={contest.id} contest={contest} />
        ))}
      </div>
    )}
  </div>
);

ActiveContestsSection.propTypes = {
  communityContests: PropTypes.arrayOf(PropTypes.shape({
    cover_image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    disable_projects_tab: PropTypes.bool.isRequired,
    end_date_in_pdt: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    is_registration_open: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    prize: PropTypes.shape({
      cash_value: PropTypes.number.isRequired,
      icon_urls: PropTypes.shape({
        x1: PropTypes.string.isRequired,
        x2: PropTypes.string.isRequired,
      }).isRequired,
      image: PropTypes.shape({ url: PropTypes.string.isRequired }),
      name: PropTypes.string.isRequired,
    }).isRequired,
    sponsors: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    })).isRequired,
    start_date_in_pdt: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    submissions_url: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })).isRequired,
  partnerContests: PropTypes.arrayOf(PropTypes.shape({
    cover_image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    disable_projects_tab: PropTypes.bool.isRequired,
    end_date_in_pdt: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    is_registration_open: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    prize: PropTypes.shape({
      cash_value: PropTypes.number.isRequired,
      icon_urls: PropTypes.shape({
        x1: PropTypes.string.isRequired,
        x2: PropTypes.string.isRequired,
      }).isRequired,
      image: PropTypes.shape({ url: PropTypes.string.isRequired }),
      name: PropTypes.string.isRequired,
    }).isRequired,
    sponsors: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    })).isRequired,
    start_date_in_pdt: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    submissions_url: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })).isRequired,
};

export default ActiveContestsSection;
