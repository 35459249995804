import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import PopoverArrow from './PopoverArrow';

import { getPosition } from './getPosition';

import dialogTransitions from '../Dialog/transitions.css';
import colors from '../../../styles/global_ui/colors.css';
import styles from './popover.css';

function isDomElement(element) {
  return element instanceof HTMLElement || element.nodeType === 1;
}

function getArrowDirection(popoverPosition) {
  return {
    right: 'left',
    left: 'right',
    top: 'bottom',
    bottom: 'top',
  }[popoverPosition];
}

class Popover extends Component {
  constructor(props) {
    super(props);

    this.state = {
      arrowStyles: {},
      bodyStyles: {},
      arrowDirection: getArrowDirection(props.position),
      show: false,
    };

    this.getPopupPosition = this.getPopupPosition.bind(this);
    this.handleResize = this.handleResize.bind(this);

    // Flags
    this._isMounted;
    this._dismissCalled = false;

    // Ref
    this._popover;
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this._isMounted = true;
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.target !== null && !this.state.show) {
      this.setState({ show: true });
    } else if (!nextProps.target && this.state.show && !this._dismissCalled) {
      // TODO:  Instead of using the _isMounted thing.  Make delayedDismiss cancelable and cancel it in componentWillUnmount.
      // https://facebook.github.io/react/blog/2015/12/16/ismounted-antipattern.html
      this._delayedDismiss();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.target && this.props.target !== prevProps.target && isDomElement(this.props.target)) {
      this._waitForRef();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    this._isMounted = false;
  }

  getPopupPosition() {
    if (!this._popover || !this.props.target) return;

    const { adjustedArrowDirection, ...positions } = getPosition(
      this.props.position,
      this.props.target.getBoundingClientRect(),
      this._popover.getBoundingClientRect(),
      this.props.adjustments,
    );

    const arrowDirection = adjustedArrowDirection || getArrowDirection(this.props.position);

    this.setState({
      ...positions,
      arrowDirection,
    });
  }

  handleResize() {
    this.getPopupPosition();
  }

  /**
   * If theres a quick fired null in-between activated elements, like moving from input to input, we want to ignore it for
   * a given time.  This is so the leave animation isn't fired and we don't get an annoying flash.
   */
  _delayedDismiss() {
    this._dismissCalled = true;

    setTimeout(() => {
      if (this.props.target === null && this._isMounted) {
        this.setState({
          arrowStyles: {},
          bodyStyles: {},
          show: false,
        });
        this._dismissCalled = false;
      }
    }, 100);
  }

  /**
   * We need the refs positional data which may take time, this will make sure the node is mounted.
   */
  _waitForRef(loop = 0) {
    if (!this._popover && loop <= 100) {
      setTimeout(() => this._waitForRef(loop + 10), 10);
    } else {
      setTimeout(() => this.getPopupPosition(), 0);
    }
  }

  render() {
    const { hideAtScreenWidth, onMouseEnter, onMouseLeave } = this.props;

    return (
      <TransitionGroup>
        {this.state.show && window.innerWidth > hideAtScreenWidth && this.props.children
        && (
          <CSSTransition classNames={dialogTransitions} timeout={250}>
            <div
              ref={(el) => this._popover = el}
              className={styles.root}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              style={{ ...this.state.bodyStyles }}
            >
              {this.props.children}
              <PopoverArrow
                borderColor={colors['v-asphalt']}
                color="white"
                direction={this.state.arrowDirection}
                style={this.state.arrowStyles}
              />
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    );
  }
}

Popover.propTypes = {
  adjustments: PropTypes.shape({
    arrowOffset: PropTypes.number,
    targetOffset: PropTypes.number,
  }),
  hideAtScreenWidth: PropTypes.number,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']).isRequired,
  target: PropTypes.object,
};

Popover.defaultProps = {
  adjustments: { targetOffset: 30 },
  hideAtScreenWidth: 1100,
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  target: null,
};

export default Popover;
