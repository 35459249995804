import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ErrorBoundary from '../../../client/wrappers/error_boundary';
import IdeasHeader from './header';
import IdeasSubmissions from './submissions';

import SeoHandler from '../../../services/seo_handler';
import seoConfig from '../seoConfig';

import createHistory from '../../../client/reusable_components/Router/history';

import { timeRemaining } from '../helpers';
import { CONTEST_DATE_AT_TIME_FORMAT, formatContestTimestampPT } from '../../../utility/time';
import { getInObj } from '../../../utility/accessors';
import { graphQueryWithUser } from '../../../requests/graphql.js';
import { summonLoginPanel } from '../../../utility/dispatchers.js';

import { WINNERS_ANNOUNCED } from '../constants';

import currentUserService from '../../../services/current_user';
import errorHandler from '../../../services/error_handler';
import smoothScroll from '../../../client/utils/smoothScroll.js';

import layout from '../../../styles/global_ui/layout.css';

const SUBMISSIONS_ID = 'ideasSubmissions';

class ContestIdeasPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentHistoryData: null,
      currentUser: null,
      currentUserIdea: null,
      formattedDates: {
        hardwareEndDate: formatContestTimestampPT(props.challenge.free_hardware_end_date, CONTEST_DATE_AT_TIME_FORMAT),
        hardwareTimeRemaining: timeRemaining(props.challenge.free_hardware_end_date),
        hardwareWinnersAnnouncementDate: formatContestTimestampPT(props.challenge.free_hardware_winners_announced_date, CONTEST_DATE_AT_TIME_FORMAT),
      },
      initialized: false,
    };

    this.getPaginatorHook = this.getPaginatorHook.bind(this);
    this.handleClickPerUserStatus = this.handleClickPerUserStatus.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.scrollToSubmissions = this.scrollToSubmissions.bind(this);

    this.basePath = props.pathHelpers.basePath;
    this.history = createHistory(this.basePath);

    this.seoHandler = new SeoHandler({ config: seoConfig });
    this.requestPageForSubmissions;
  }

  componentDidMount() {
    this.unlisten = this.history.listen(this.handleLocationChange);
    this._fetchCurrentUserIdea();
  }

  componentWillUnmount() {
    this.unlisten();
  }

  /**
   * Initializers
   */
  _fetchCurrentUserIdea() {
    return currentUserService.fetchProperty('id', { t: 'get_id_for_current_user' })
      .then((id) => this._fetchUserIdea(id))
      .then((currentUserData) => this.setState({
        ...currentUserData,
        initialized: true,
      }))
      .catch((err) => errorHandler('ContestIdeasPage _fetchCurrentUserData', err));
  }

  _fetchUserIdea(id) {
    return new Promise((resolve, reject) => {
      if (typeof id !== 'number') return resolve({ currentUser: null, currentUserIdea: null });

      return graphQueryWithUser({ t: 'get_current_user_ideas' }, { challenge_id: this.props.challenge.id })
        .then(({ user }) => {
          const currentUserData = {
            currentUser: { id },
            currentUserIdea: user.challenge_ideas.length > 0 ? user.challenge_ideas[0] : null,
          };

          resolve(currentUserData);
        })
        .catch((err) => reject(err));
    });
  }

  /**
   * Methods
   */
  handleClickPerUserStatus(e) {
    if (this.state.currentUser) return;
    e.preventDefault();
    summonLoginPanel();
  }

  handleLocationChange(pathData, action) {
    // If it has an entity in state, report it as single idea page. Otherwise it is a pagination of /ideas
    const idea = getInObj(['state', 'entity'], pathData);

    if (idea) {
      this.seoHandler.reportView({ path: '/ideas/*', data: idea });
    } else {
      this.seoHandler.reportView({ path: '/ideas', data: this.props.challenge });
    }

    const currentHistoryData = { ...pathData, action };
    this.setState({ currentHistoryData });
  }

  getPaginatorHook(fn) {
    this.requestPageForSubmissions = fn;
  }

  scrollToSubmissions(e, contestState = null) {
    e.preventDefault();

    if (contestState === WINNERS_ANNOUNCED) {
      this._paginateToPageOne();
    } else {
      const node = document.getElementById(SUBMISSIONS_ID);
      if (node) smoothScroll(node);
    }
  }

  _paginateToPageOne() {
    if (typeof this.requestPageForSubmissions === 'function') {
      this.requestPageForSubmissions(1);
    }
  }

  validatePaginatorRefetch(currentProps, nextProps) {
    return !getInObj(['currentHistoryData', 'state', 'entity'], currentProps);
  }

  render() {
    return (
      <div className={layout.container}>
        <div className={`${layout.wrapper1170} ${layout.fullScreenHeight}`}>
          <IdeasHeader
            basePath={this.basePath}
            challenge={this.props.challenge}
            currentUserIdea={this.state.currentUserIdea}
            formattedDates={this.state.formattedDates}
            handleClickPerUserStatus={this.handleClickPerUserStatus}
            initialized={this.state.initialized}
            scrollToSubmissions={this.scrollToSubmissions}
          />
          <ErrorBoundary>
            <IdeasSubmissions
              basePath={this.basePath}
              challenge={this.props.challenge}
              currentHistoryData={this.state.currentHistoryData}
              getPaginatorHook={this.getPaginatorHook}
              history={this.history}
              id={SUBMISSIONS_ID}
              validatePaginatorRefetch={this.validatePaginatorRefetch}
            />
          </ErrorBoundary>
        </div>
      </div>
    );
  }
}

ContestIdeasPage.propTypes = {
  challenge: PropTypes.shape({
    end_date: PropTypes.string.isRequired,
    free_hardware_end_date: PropTypes.string,
    free_hardware_image_url: PropTypes.string.isRequired,
    free_hardware_name: PropTypes.string.isRequired,
    free_hardware_quantity: PropTypes.number.isRequired,
    free_hardware_quantity_remaining: PropTypes.number.isRequired,
    free_hardware_status: PropTypes.string.isRequired,
    free_hardware_winners_announced_date: PropTypes.string,
    free_hardware_url: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    show_submissions_before_judged: PropTypes.bool,
    status: PropTypes.string.isRequired,
    support_email: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  pathHelpers: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
    fullPath: PropTypes.string.isRequired,
    rootPath: PropTypes.string.isRequired,
  }).isRequired,
};

ContestIdeasPage.defaultProps = {};

export default ContestIdeasPage;
