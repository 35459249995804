import { getInObj } from '../../../../utility/accessors';

export default function projects({
  authors,
  cover_image_url,
  _highlightResult,
  id,
  impressions_count,
  name,
  respects_count,
  url,
}, options = {}) {
  return {
    body: { htmlString: getInObj(['pitch', 'value'], _highlightResult) },
    id: id,
    image: {
      linkTo: url,
      loadBuffer: 100,
      ratio: '4:3',
      url: cover_image_url,
      width: 100,
    },
    stats: [
      { metric: impressions_count, text: 'View' },
      { metric: respects_count, text: 'Respect' },
    ],
    subTitle: (Array.isArray(authors) && authors.length) ? authors.map((a) => ({ text: a.name, linkTo: a.url })) : null,
    title: {
      text: name,
      linkTo: url,
    },
  };
}
