import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as RadixTooltip from '@radix-ui/react-tooltip';

import Icon from '../../icon';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';
import styles from './tooltip.css';

const Tooltip = ({ children, classList, iconProps, content, ...radixContentProps }) => {
  const [open, setOpen] = useState(false);

  return (
    <RadixTooltip.Provider delayDuration={200}>
      <RadixTooltip.Root open={open}>
        <RadixTooltip.Trigger asChild>
          <button
            className={styles.btn}
            onBlur={() => setOpen(false)}
            onFocus={() => setOpen(true)}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            onTouchStart={() => setOpen(!open)}
            type="button"
          >
            {children ? children : <Icon {...iconProps} className={`${typography.hackster} ${classList.icon}`} />}
          </button>
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            {...radixContentProps}
            className={
              `${typography.bodyM} ${utilStyles.borderRadiusLarge} ${utilStyles.border}
              ${utilStyles.bgWhite} ${utilStyles.boxShadow} ${layout.padding2015} ${styles.content} ${classList.content}`
            }
          >
            {content}
            <RadixTooltip.Arrow asChild className={`${typography.hackster} ${styles.arrow}`}>
              <svg
                fill="none"
                height="12"
                viewBox="0 0 24 12"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect height="2" width="24" />
                <path
                  d="M24 1C18 1 18 11 12 11C6 11 6 0.999999 8.74228e-07 0.999999"
                />
              </svg>
            </RadixTooltip.Arrow>
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

Tooltip.propTypes = {
  align: PropTypes.oneOf(['start', 'center', 'end']),
  alignOffset: PropTypes.number,
  arrowPadding: PropTypes.number,
  children: PropTypes.any,
  classList: PropTypes.shape({
    content: PropTypes.string,
    icon: PropTypes.string,
  }),
  collisionPadding: PropTypes.oneOfType([
    PropTypes.shape({
      bottom: PropTypes.number,
      left: PropTypes.number,
      right: PropTypes.number,
      top: PropTypes.number,
    }),
    PropTypes.number,
  ]),
  content: PropTypes.any,
  iconProps: PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.number,
  }),
  side: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  sideOffset: PropTypes.number,
};

Tooltip.defaultProps = {
  align: 'center',
  alignOffset: 0,
  arrowPadding: 0,
  children: null,
  classList: {
    content: '',
    icon: '',
  },
  collisionPadding: 0,
  content: null,
  iconProps: {
    name: 'info-circle',
    size: 16,
  },
  side: 'bottom',
  sideOffset: 10,
};

export default Tooltip;
