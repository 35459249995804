import React from 'react';
import PropTypes from 'prop-types';

import AddToInterestsButton from '../../buttons/add_to_interests_button';
import BuyButton from '../../buttons/buy_button';
import AnchorButton from '../../buttons/anchor';
import LazyImage from '../../reusable_components/LazyImage';

import { getInObj } from '../../../utility/accessors';

import styles from './horizontal_part_card.css';
import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';

const HorizontalPartCard = ({ canManage, className, part, platformId, showLinks, transition }) => (
  <div className={`${styles.container} ${className}`}>
    <a className={styles.imageContainer} href={part.url} onClick={(e) => e.preventDefault()}>
      {part.image_url
        && (
          <LazyImage
            className={styles.image}
            fit="fill"
            onClick={(e) => transition('projects', part.slug, e)}
            queryParams={{ bg: 'fff' }}
            ratio="3:2"
            src={part.image_url}
            width={255}
          />
        )}
    </a>

    <div className={styles.content}>
      <div>
        <h3 className={styles.name}>
          <a
            className={typography.link}
            href={part.url}
            onClick={(e) => {
              e.preventDefault();
              transition('projects', part.slug, e);
            }}
          >
            {part.name}
          </a>
        </h3>

        {showLinks
          && (
            <div className={styles.links}>
              {getInObj(['links', 'get_started'], part) && React.Children.toArray([
                <a className={typography.linkBlue} href={part.links.get_started} rel="noopener nofollow noreferrer" target="_blank">Getting Started</a>,
                <span className={styles.linkDivider}>{String.fromCharCode(124)}</span>,
              ])}
              <a
                className={typography.linkBlue}
                href={part.url}
                onClick={(e) => {
                  e.preventDefault();
                  transition('projects', part.slug, e);
                }}
              >
                View projects
              </a>
            </div>
          )}

        {part.one_liner && <div className={`${styles.description} ${typography.bodyM}`}>{part.one_liner}</div>}
      </div>

      <div className={styles.buttons}>
        {part.online_stores.length > 0 && <BuyButton partId={part.id} stores={part.online_stores} />}
        <AddToInterestsButton partId={part.id} />
        {canManage
          && (
            <AnchorButton
              className={layout.marginRight10}
              colorStyle="tertiary"
              href={`/groups/${platformId}/products/${part.id}/edit`}
            >
              Edit
            </AnchorButton>
          )}
      </div>
    </div>
  </div>
);

HorizontalPartCard.propTypes = {
  canManage: PropTypes.bool,
  className: PropTypes.string,
  part: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.number.isRequired,
    image_url: PropTypes.string,
    links: PropTypes.shape({ get_started: PropTypes.string }),
    name: PropTypes.string,
    one_liner: PropTypes.string,
    online_stores: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      link: PropTypes.string,
      retailer: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    })),
    slug: PropTypes.string,
    url: PropTypes.string.isRequired,
  }).isRequired,
  platformId: PropTypes.number.isRequired,
  showLinks: PropTypes.bool,
  transition: PropTypes.func,
};

HorizontalPartCard.defaultProps = {
  canManage: false,
  className: '',
  part: {
    description: null,
    image_url: null,
    links: { get_started: null },
    name: null,
    one_liner: null,
    online_stores: [],
    slug: '',
  },
  showLinks: true,
  transition: () => {},
};

export default HorizontalPartCard;
