/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { graphQueryWithUser, graphQueryWithUserNoSigninDialog, graphMutate } from '../../../requests/graphql';

import ContestBriefSidePanel from './side_panel';
import Dialog from '../../../client/reusable_components/Dialog';
import Prompt from '../../../client/reusable_components/Dialog/Prompt';
import PostRegistrationDialog from './post_registration_dialog';

import currentUserService from '../../../services/current_user';
import errorHandler from '../../../services/error_handler';
import keenService from '../../../services/keen/main';
import { getCreateContestEntryArgs, getCreateContestRegistrationArgs, getDeleteContestRegistrationArgs } from '../../../services/keen/events/eventTypeTemplates';
import { getErrorHelperView } from '../../../client/form_components/templates';

import { APPROVED, ENTRY, IDEA, IN_PROGRESS, OPEN, RESUBMITTED } from '../constants.js';
import { addRegisterButtonClickHandler, scrollToNav, updateDOMRegisteredState } from './DOMHelper';
import { formatEvents, formatStatus } from './formatters';
import { timeTilEndDateMs } from '../helpers';
import { pluralize } from '../../../utility/formatters.js';
import { objHasPropertyWithValue } from '../../../utility/predicates';
import { summonLoginPanel } from '../../../utility/dispatchers.js';
import { doesWindowLocationHaveParam, windowLocationReload } from '../../../services/window';

const _serverError = (noun, verb) => `${noun} could not be ${verb}, try again.`;

const INIT_DIALOG_PARAM = 'reg_modal';
const PROJECT_PANEL_CREATE_ERROR = _serverError('Project', 'added');
const PROJECT_PANEL_DELETE_ERROR = _serverError('Project', 'withdrawn');
const PROJECT_PANEL_DELETE_IDEA_ERROR = _serverError('Idea', 'withdrawn');
const PROJECT_PANEL_RESUBMIT_ERROR = _serverError('Project', 'resubmitted');
const UNREGISTER_ERROR = 'Could not unregister, please try again';

class ContestBriefPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contestStatus: formatStatus(props.challenge),
      dialog: {
        data: null,
        open: false,
        view: null,
      },
      fetchedUserProjects: false,
      initialized: false,
      isBusy: false,
      prompt: {
        data: null,
        open: false,
        view: null,
      },
      serverErrors: {
        createEntry: null,
        deleteEntry: null,
        deleteIdea: null,
        deleteRegistration: null,
      },
      timelineEvents: formatEvents(props.challenge),
      user: {
        entries: [],
        ideas: [],
        isRegistered: false,
        projects: [],
        registration: null,
      },
    };

    this.createEntry = this.createEntry.bind(this);
    this.createRegistration = this.createRegistration.bind(this);
    this.deleteEntry = this.deleteEntry.bind(this);
    this.deleteIdea = this.deleteIdea.bind(this);
    this.deleteRegistration = this.deleteRegistration.bind(this);
    this.getUserProjects = this.getUserProjects.bind(this);
    this.handleExternalRegisterButtonClick = this.handleExternalRegisterButtonClick.bind(this);
    this.resubmitEntry = this.resubmitEntry.bind(this);

    this._refreshTimeout;
  }

  componentDidMount() {
    this._init();
    this._initRefreshTimeout();
    addRegisterButtonClickHandler(this.handleExternalRegisterButtonClick);
  }

  componentWillUnmount() {
    if (this._refreshTimeout) {
      window.clearTimeout(this._refreshTimeout);
    }
  }

  /**
   * Initializers
   */
  _init() {
    return graphQueryWithUserNoSigninDialog({ t: 'get_contest_dashboard_index' }, { id: this.props.challenge.id })
      .then(({ current_user }) => {
        const user = this._initCurrentUser(current_user);

        this.setState({
          dialog: this._initDialog(user),
          initialized: true,
          user,
        });
      })
      .catch((err) => {
        this.setState({ initialized: true });
        errorHandler('ContestBriefPage _init', err);
      });
  }

  _initCurrentUser(user) {
    if (!user || !user.hasOwnProperty('challenge_participation')) return this.state.user;

    const { entries, ideas, registration } = user.challenge_participation;

    return {
      entries,
      ideas,
      isRegistered: objHasPropertyWithValue(registration, 'id'),
      projects: [],
      registration,
    };
  }

  _initDialog(user) {
    if (user.isRegistered && doesWindowLocationHaveParam(INIT_DIALOG_PARAM)) {
      return { data: this.props.challenge, open: true, view: 'PostRegistrationDialog' };
    }

    return this.state.dialog;
  }

  _initRefreshTimeout() {
    const timeLeft = timeTilEndDateMs(this.props.challenge.end_date);
    if (timeLeft > 0) {
      this._refreshTimeout = setTimeout(() => windowLocationReload(true), timeLeft);
    }
  }

  /**
   * Methods
   */
  createEntry(projectId, closePanelHook) {
    this.setState({ isBusy: true });

    return graphMutate({ t: 'create_contest_entry' }, { challenge_id: this.props.challenge.id, project_id: projectId })
      .then(({ created }) => {
        this.setState({
          isBusy: false,
          serverErrors: {
            ...this.state.serverErrors,
            createEntry: null,
          },
          user: {
            ...this.state.user,
            entries: [created.entry].concat(this.state.user.entries),
            projects: this.state.user.projects.filter((p) => p.id !== projectId),
          },
        }, () => closePanelHook());

        keenService.reportEventWithObj(getCreateContestEntryArgs({
          id: this.props.challenge.id,
          project_id: projectId,
        }));
      })
      .catch((err) => {
        this.setState({
          isBusy: false,
          serverErrors: { ...this.state.serverErrors, createEntry: PROJECT_PANEL_CREATE_ERROR },
        });
        errorHandler('ContestBriefPage createEntry', err);
      });
  }

  createRegistration() {
    if (!currentUserService.has('id')) {
      return summonLoginPanel({
        detail: {
          redirect_to: this.props.registrationPath,
          state: { currentPanel: 'signup', simplified: true },
        },
      });
    }

    this.setState({ isBusy: true });

    return graphMutate({ t: 'create_contest_registration' }, { challenge_id: this.props.challenge.id })
      .then(({ current_user }) => {
        this.setState({
          dialog: { data: this.props.challenge, open: true, view: 'PostRegistrationDialog' },
          fetchedUserProjects: false,
          isBusy: false,
          serverErrors: {
            ...this.state.serverErrors,
            createRegistration: null,
          },
          user: this._initCurrentUser(current_user),
        });

        keenService.reportEventWithObj(getCreateContestRegistrationArgs({ id: this.props.challenge.id }));
        updateDOMRegisteredState(true);
        scrollToNav();
      })
      .catch((err) => {
        this.setState({ isBusy: false });
        errorHandler('ContestBriefPage createRegistration', err);
      });
  }

  deleteEntry(entry) {
    this.setState({ isBusy: true });

    return graphMutate({ t: 'delete_contest_entry' }, { id: entry.id })
      .then(() => this.setState({
        isBusy: false,
        prompt: { data: null, open: false, view: null },
        serverErrors: {
          ...this.state.serverErrors,
          deleteEntry: null,
        },
        user: {
          ...this.state.user,
          entries: this.state.user.entries.filter((e) => e.id !== entry.id),
          projects: this.state.user.projects.concat({ id: entry.project.id, name: entry.project.name }),
        },
      }))
      .catch((err) => {
        this.setState({
          isBusy: false,
          serverErrors: { ...this.state.serverErrors, deleteEntry: PROJECT_PANEL_DELETE_ERROR },
        });
        errorHandler('ContestBriefPage deleteEntry', err);
      });
  }

  deleteIdea(idea) {
    this.setState({ isBusy: true });

    return graphMutate({ t: 'delete_contest_idea' }, { id: idea.id })
      .then(() => this.setState({
        prompt: { data: null, open: false, view: null },
        isBusy: false,
        serverErrors: {
          ...this.state.serverErrors,
          deleteIdea: null,
        },
        user: {
          ...this.state.user,
          ideas: this.state.user.ideas.filter((e) => e.id !== idea.id),
        },
      }))
      .catch((err) => {
        this.setState({
          isBusy: false,
          serverErrors: { ...this.state.serverErrors, deleteIdeas: PROJECT_PANEL_DELETE_IDEA_ERROR },
        });
        errorHandler('ContestBriefPage deleteIdea', err);
      });
  }

  deleteRegistration() {
    this.setState({ isBusy: true });

    return graphMutate({ t: 'delete_contest_registration' }, { id: this.state.user.registration.id })
      .then(() => {
        this.setState({
          fetchedUserProjects: false,
          isBusy: false,
          prompt: { data: null, open: false, view: null },
          serverErrors: {
            ...this.state.serverErrors,
            deleteRegistration: null,
          },
          user: {
            ...this.state.user,
            isRegistered: false,
            registration: null,
          },
        });

        keenService.reportEventWithObj(getDeleteContestRegistrationArgs({ id: this.props.challenge.id }));
        updateDOMRegisteredState(false);
      })
      .catch((err) => {
        this.setState({
          isBusy: false,
          serverErrors: { ...this.state.serverErrors, deleteRegistration: UNREGISTER_ERROR },
        });
        errorHandler('ContestBriefPage deleteRegistration', err);
      });
  }

  getUserProjects() {
    return new Promise((resolve, reject) => {
      if (this.state.fetchedUserProjects) return resolve(this.state.user.projects);
      this.setState({ isBusy: true });

      return graphQueryWithUser({ t: 'get_user_projects_for_contests' }, { challenge_id: this.props.challenge.id })
        .then(({ current_user }) => {
          this.setState((state) => ({
            fetchedUserProjects: true,
            isBusy: false,
            user: {
              ...state.user,
              projects: current_user.projects,
            },
          }));

          resolve(current_user.projects);
        })
        .catch((err) => {
          this.setState({ isBusy: false });
          errorHandler('ContestBriefPage getUserProjects', err);
          reject(err);
        });
    });
  }

  resubmitEntry(entry) {
    this.setState({ isBusy: true });

    return graphMutate({ t: 'submit_contest_entry' }, { id: entry.id })
      .then(() => this.setState({
        prompt: { data: null, open: false, view: null },
        isBusy: false,
        serverErrors: {
          ...this.state.serverErrors,
          resubmitEntry: null,
        },
        user: {
          ...this.state.user,
          entries: this.state.user.entries.map((e) => e.id === entry.id ? this._updateEntryStatus(e, RESUBMITTED) : e),
        },
      }))
      .catch((err) => {
        this.setState({
          isBusy: false,
          serverErrors: { ...this.state.serverErrors, deleteEntry: PROJECT_PANEL_RESUBMIT_ERROR },
        });
        errorHandler('ContestBriefPage resubmitEntry', err);
      });
  }

  handleExternalRegisterButtonClick(e) {
    e.preventDefault();
    this.createRegistration();
  }

  /**
   * Helpers
   */
  _getEditSubmissionPermissions() {
    return {
      [ENTRY]: this.props.challenge.status === IN_PROGRESS,
      [IDEA]: this.props.challenge.free_hardware_status === OPEN,
    };
  }

  _summonPrompt(record, type) {
    const typeMap = {
      [ENTRY]: 'deleteEntry',
      [IDEA]: 'deleteIdea',
      resubmit: 'resubmitEntry',
      unregister: 'deleteRegistration',
    };

    if (typeMap.hasOwnProperty(type)) {
      this.setState({ prompt: { data: record, open: true, view: typeMap[type] } });
    }
  }

  _updateEntryStatus(entry, status) {
    return {
      ...entry,
      status,
    };
  }

  /**
   * Views
   */
  _getDialogBody() {
    switch (this.state.dialog.view) {
      case 'PostRegistrationDialog':
        // TODO: Refactor this. Disabling for linter clean-up
        // eslint-disable-next-line no-case-declarations
        const props = this.state.dialog.data ? this.state.dialog.data : {};

        return (<PostRegistrationDialog challengeId={this.props.challenge.id} {...props} />);
      default:
        return null;
    }
  }

  _getUnregisterPromptIdeasSection(ideas = []) {
    // TODO: make sure ideas don't come in as approved if winners have not been announced
    if (ideas.some((idea) => idea.status === APPROVED)) {
      return (
        <div>
          {'You’ve been awarded a piece of hardware. According to our '}
          <a href={this.props.rulesPath}>contest rules</a>
          , participants who receive free hardware and do not submit a project may be ineligible for future giveaways unless the hardware is returned.
        </div>
      );
    }
    if (ideas.length > 0) {
      return (
        <div>
          {`Your ${pluralize('idea', ideas.length)} will be withdrawn and will not be considered for free hardware.`}
        </div>
      );
    }

    return null;
  }

  _getUnregisterPromptBody() {
    const entriesCount = this.state.user.entries.length;

    return (
      <Fragment>
        {this._getUnregisterPromptIdeasSection(this.state.user.ideas)}
        {entriesCount > 0
        && (
          <div>
            {`Your ${pluralize('submission', entriesCount)} will be withdrawn and will not be considered for prizes.`}
          </div>
        )}
      </Fragment>
    );
  }

  _getPromptCopy(methodName) {
    switch (methodName) {
      case 'deleteEntry':
        return {
          action: 'Withdraw',
          title: 'Are you sure you want to withdraw your submission?',
          body: 'If you withdraw you can resubmit later.',
        };
      case 'deleteIdea':
        return {
          action: 'Withdraw',
          title: 'Are you sure you want to withdraw your idea?',
          body: 'Once you withdraw your idea, it cannot be undone.',
        };
      case 'resubmitEntry':
        return {
          action: 'Resubmit',
          title: 'Are you sure you want to resubmit your entry?',
          body: "Please confirm you've made updates according to the moderators' feedback.",
        };
      case 'deleteRegistration':
        return {
          body: this._getUnregisterPromptBody(),
          title: 'Are you sure you want to unregister?',
        };
      default:
        return {};
    }
  }

  _getPromptProps(methodName) {
    const { action, body, title } = this._getPromptCopy(methodName);

    return {
      action,
      body,
      message: getErrorHelperView(this.state.serverErrors[methodName]),
      okay: () => {
        if (this.hasOwnProperty(methodName)) {
          this[methodName](this.state.prompt.data);
        }
      },
      title,
    };
  }

  render() {
    return (
      <div>
        <ContestBriefSidePanel
          allowMultipleEntries={this.props.challenge.allow_multiple_entries}
          canEditSubmission={this._getEditSubmissionPermissions()}
          contestStatus={this.state.contestStatus}
          createEntry={this.createEntry}
          createRegistration={this.createRegistration}
          deleteRegistration={() => this._summonPrompt(null, 'unregister')}
          editNotificationsPath={this.props.editNotificationsPath}
          faqPath={this.props.faqPath}
          getUserProjects={this.getUserProjects}
          initialized={this.state.initialized}
          isBusy={this.state.isBusy}
          newProjectPath={this.props.newProjectPath}
          resubmitEntry={(entry) => this._summonPrompt(entry, 'resubmit')}
          serverErrors={this.state.serverErrors}
          timelineEvents={this.state.timelineEvents}
          user={this.state.user}
          withdrawEntryOrIdea={(record, type) => this._summonPrompt(record, type)}
        />
        <Dialog
          dismiss={() => this.setState({ dialog: { data: null, open: false, view: null } })}
          open={this.state.dialog.open}
        >
          {this._getDialogBody()}
        </Dialog>
        <Prompt
          dismiss={() => this.setState({ prompt: { data: null, open: false, view: null } })}
          isBusy={this.state.isBusy}
          open={this.state.prompt.open}
          {...this._getPromptProps(this.state.prompt.view)}
        >
        </Prompt>
      </div>
    );
  }
}

ContestBriefPage.propTypes = {
  challenge: PropTypes.shape({
    allow_multiple_entries: PropTypes.bool.isRequired,
    end_date: PropTypes.string,
    free_hardware_end_date: PropTypes.string,
    free_hardware_winners_announced_date: PropTypes.string,
    id: PropTypes.number.isRequired,
    ready: PropTypes.bool,
    start_date: PropTypes.string,
    status: PropTypes.string.isRequired,
    winners_announced_date: PropTypes.string,
  }).isRequired,
  editNotificationsPath: PropTypes.string.isRequired,
  faqPath: PropTypes.string.isRequired,
  newProjectPath: PropTypes.string.isRequired,
  registrationPath: PropTypes.string.isRequired,
  rulesPath: PropTypes.string.isRequired,
  user_signed_in: PropTypes.bool.isRequired,
};

export default ContestBriefPage;
