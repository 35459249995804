import React from 'react';
import PropTypes from 'prop-types';

import EditableProjectsList from '../../projects/projects_list/EditableProjectsList';

import { goTo } from '../../reusable_components/Router';

import buttonStyles from '../../../styles/global_ui/buttons.css';
import layout from '../../../styles/global_ui/layout.css';
import styles from '../edit/dashboard/dashboard.css';

export const labels = {
  all: { title: 'Projects' },
  drafts: { title: 'Drafts' },
  public: { title: 'Public projects' },
  guest: { title: 'Guest projects' },
};

const ProjectsSection = ({ basePath, cardBorder, category, currentUser, displayCount, handleReorder, projects, toggleRespect, totalRecords, path }) => {
  const url = path || `projects/${category}`;
  const href = basePath ? `${basePath}/${url}` : url;

  return projects.length > 0 && (
    <section key={category} className={styles.section}>
      <header className={styles.sectionHeader}>
        <a className={styles.headerTitle} href={href} onClick={(e) => goTo(e, url)}>
          {labels[category].title}
        </a>
        <span className={styles.headerCount}>{totalRecords.toString()}</span>
      </header>
      <div className={styles.projectList}>
        <EditableProjectsList
          cardBorder={cardBorder}
          handleReorder={handleReorder}
          horizontal={true}
          maxCols={3}
          orderable={category === 'public'}
          records={projects.slice(0, displayCount)}
          toggleRespect={toggleRespect}
        />
      </div>
      {totalRecords > displayCount

      && (
        <button
          className={`${buttonStyles.sm} ${buttonStyles.tertiary} ${buttonStyles.pill} ${layout.flexAlignSelfEnd}`}
          onClick={(e) => goTo(e, url)}
        >
          View all
        </button>
      )}
    </section>
  );
};

ProjectsSection.propTypes = {
  basePath: PropTypes.string,
  cardBorder: PropTypes.bool,
  category: PropTypes.string.isRequired,
  currentUser: PropTypes.shape({ id: PropTypes.number }),
  displayCount: PropTypes.number,
  handleReorder: PropTypes.func,
  path: PropTypes.string,
  projects: PropTypes.array,
  toggleRespect: PropTypes.func,
  totalRecords: PropTypes.number,
};

ProjectsSection.defaultProps = {
  basePath: null,
  cardBorder: false,
  currentUser: {},
  displayCount: 6,
  handleReorder: () => Promise.resolve(),
  path: null,
  projects: [],
  toggleRespect: () => Promise.resolve(),
  totalRecords: 0,
};

export default ProjectsSection;
