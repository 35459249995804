import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Link from '../../../client/link';
import UserCard from '../../../client/cards/user_card';

import { CONTEST_DATE_AT_TIME_FORMAT, formatContestTimestampPT } from '../../../utility/time';
import { isBlank } from '../../../utility/types';

import layoutStyles from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import util from '../../../styles/global_ui/util.css';
import styles from './idea_page.css';

const adminSections = {
  fullSection: ['skills'],
  oneLine: ['hours_weekly', 'hours_total', 'owned'],
};

const bodySections = ['problem', 'build', 'solution', 'components'];

const sectionHeaders = {
  build: () => 'What are you going to build to solve this problem? How is it different from existing solutions? Why is it useful?',
  problem: () => 'What problem are you going to solve?',
  solution: (hardwareName) => `How does your solution work? What are the main features? Please specify how you will use the ${hardwareName} in your solution.`,
  components: () => 'List the hardware and software you will use to build this.',
  skills: () => 'Tell us about yourself. What do you spend most of your time doing? What skills or experience do you have that will enable you to be successful in building this project?',
  hours_weekly: () => 'Time commitment: ',
  hours_total: () => 'Estimated time required: ',
  owned: () => 'Purchased hardware: ',
};

/**
 * Helpers
 */
const _isOldIdeaFormat = (idea) => !!idea.old_html_description;

const _shouldShowAdminSections = ({ fullPage, idea }) => (
  fullPage && !_isOldIdeaFormat(idea) && _userCanViewAdminSections(idea)
);

const _userCanViewAdminSections = (idea) => (
  idea.current_user_permissions.edit || idea.current_user_permissions.admin || idea.current_user_permissions.moderate
);

/**
 * Views
 */
const _getAdminSections = (idea) => (
  <Fragment>
    {adminSections.fullSection.map((fieldName) => _getSection({ fieldName, idea }))}
    <div className={layoutStyles.marginTop30}>
      {adminSections.oneLine.map((key) => (
        <div key={key}>
          <span className={typography.h3}>{sectionHeaders[key]()}</span>
          <span className={typography.bodyL}>{idea.application[key]}</span>
        </div>
      ))}
    </div>
  </Fragment>
);

const _getBodySections = ({ hardwareName, idea }) => (
  bodySections.map((fieldName) => _getSection({ fieldName, idea, hardwareName }))
);

const _getExtraFields = (idea) => {
  if (isBlank(idea.extra_fields)) return;

  const fields = _userCanViewAdminSections(idea)
    ? idea.extra_fields.filter((f) => isBlank(f.answer) === false)
    : idea.extra_fields.filter((f) => (f.hide === false && isBlank(f.answer) === false));

  return React.Children.toArray(fields.map((f) => (
    <div className={layoutStyles.marginTop30}>
      <h3 className={typography.h3}>{f.label}</h3>
      {
        f.input_type === 'image'
          ? (
            <img
              alt={`image entry for ${f.label}`}
              className={`${util.border} ${util.borderRadius}`}
              src={f.answer}
            />
            )
          : (
            <p className={typography.bodyL}>
              {f.input_type === 'multi'
                ? JSON.parse(f.answer).map((item) => item).join(', ')
                : f.answer}
            </p>
            )
        }
    </div>
  )));
};

const _getSection = ({ fieldName, hardwareName, idea }) => (
  <div key={fieldName} className={layoutStyles.marginTop30}>
    <h3 className={typography.h3}>{sectionHeaders[fieldName](hardwareName)}</h3>
    <p className={`${typography.bodyL} ${typography.whitespacePre}`}>{idea.application[fieldName]}</p>
  </div>
);

const IdeaView = ({ fullPage, idea, hardwareName }) => (
  <div className={layoutStyles.fullWidth}>
    <UserCard titleSize="S" user={idea.user}>
      <span className={`${typography.bodyS} ${typography.pebble}`}>
        {`Submitted ${formatContestTimestampPT(idea.submitted_at_pt, CONTEST_DATE_AT_TIME_FORMAT)}`}
      </span>
    </UserCard>
    <h1 className={`${typography.h1} ${layoutStyles.marginTop15} ${styles.ideaHeader}`}>
      {idea.name}
    </h1>
    {_isOldIdeaFormat(idea)
      ? <div dangerouslySetInnerHTML={{ __html: idea.old_html_description }} className={layoutStyles.marginTop30} />
      : _getBodySections({ hardwareName, idea })}
    {_getExtraFields(idea)}
    {_shouldShowAdminSections({ fullPage, idea }) && _getAdminSections(idea)}
    <div className={layoutStyles.marginTop30}>
      {idea.current_user_permissions.edit
      && (
        <Link bold={true} className={layoutStyles.marginRight15} color="Blue" href={`${idea.url}/edit`} size="S">
          Edit
        </Link>
      )}
      {!fullPage && (idea.current_user_permissions.admin || idea.current_user_permissions.moderate)
      && (
        <Link bold={true} color="Blue" href={idea.url} size="S">
          Go to judging panel
        </Link>
      )}
    </div>
  </div>
);

IdeaView.propTypes = {
  fullPage: PropTypes.bool,
  hardwareName: PropTypes.string.isRequired,
  idea: PropTypes.shape({
    application: PropTypes.shape({
      build: PropTypes.string,
      components: PropTypes.string,
      hours_total: PropTypes.number, // admin
      hours_weekly: PropTypes.number, // admin
      owned: PropTypes.string, // admin
      problem: PropTypes.string,
      skills: PropTypes.string, // admin
      solution: PropTypes.string,
    }),
    created_at: PropTypes.string.isRequired,
    current_user_permissions: PropTypes.shape({
      admin: PropTypes.bool.isRequired,
      edit: PropTypes.bool.isRequired,
      moderate: PropTypes.bool.isRequired,
    }).isRequired,
    extra_fields: PropTypes.arrayOf(
      PropTypes.shape({
        answer: PropTypes.string.isRequired,
        hide: PropTypes.bool.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ).isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    old_html_description: PropTypes.string,
    project: PropTypes.shape({
      cover_image_url: PropTypes.string,
      hid: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
    submitted_at: PropTypes.string.isRequired,
    user: PropTypes.shape({
      avatar_url: PropTypes.string.isRequired,
      challenge_stats: PropTypes.shape({ // admin
        entries_submitted: PropTypes.number,
        entries_won: PropTypes.number,
        ideas_awarded: PropTypes.number,
        ideas_submitted: PropTypes.number,
        registrations: PropTypes.number,
        status: PropTypes.string,
      }),
      country: PropTypes.string, // admin
      created_at: PropTypes.string, // admin
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      stats: PropTypes.shape({ live_projects: PropTypes.number }), // admin
      url: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

IdeaView.defaultProps = { fullPage: false };

export default IdeaView;
