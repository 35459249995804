import React from 'react';
import PropTypes from 'prop-types';

import { isCurrentPath, history, Link } from '../../../reusable_components/Router';
import urlService from '../../../../services/url_service';

import layout from '../../../../styles/global_ui/layout.css';
import styles from '../../../../styles/navbar.css';

function getHref(route, basePath, rootPath) {
  return route.redirect ? urlService.url(route.href) : `${basePath}${route.href}`;
}

const NavBar = ({ basePath, initPath, rootPath, routes, store, transition }) => (
  <div className={`${styles.themeDark} ${styles.fullWidth} ${layout.flexJustifyCenter}`}>
    <ul className={`${styles.list} ${layout.noScrollBar} ${layout.wrapper1170}`}>
      {routes.map((route, i) => (
        <li
          key={i}
          className={styles.listItem}
        >
          <Link
            classList={{
              anchor: isCurrentPath(history, route, initPath) ? styles.linkFocused : styles.link,
              root: isCurrentPath(history, route, initPath) ? styles.linkWrapperFocused : styles.linkWrapper,
            }}
            href={getHref(route, basePath, rootPath)}
            name={route.name}
            onClick={() => transition(route.href, store)}
            redirect={route.redirect}
          >
            {route.dropdown
            && (
              <div className={styles.dropdown}>
                {route.dropdown.map((subroute, ii) => (
                  <Link
                    key={ii}
                    classList={{ anchor: styles.link, root: styles.subLinkWrapper }}
                    href={getHref(subroute, basePath, rootPath)}
                    name={subroute.name}
                    onClick={() => transition(subroute.href, store)}
                    redirect={subroute.redirect}
                  />
                ))}
              </div>
            )}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

NavBar.propTypes = {
  basePath: PropTypes.string.isRequired,
  initPath: PropTypes.string.isRequired,
  rootPath: PropTypes.string.isRequired,
  routes: PropTypes.array.isRequired,
  store: PropTypes.object.isRequired,
  transition: PropTypes.func.isRequired,
};

export default NavBar;
