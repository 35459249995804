import PropTypes from 'prop-types';
import React from 'react';

import typography from '../../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../../styles/global_ui/util.css';

const SubTitle = ({ classList, content, withCommas }) => {
  if (
    !content
    // TODO: figure out what this logic _should_ be and wrap in parens to make more clear
    // eslint-disable-next-line @stylistic/no-mixed-operators
    || Array.isArray(content) && !content.length
    // TODO: figure out what this logic _should_ be and wrap in parens to make more clear
    // eslint-disable-next-line @stylistic/no-mixed-operators
    || content.length === 1 && !content[0].text
  ) return null;

  return (
    <div className={`${classList.subTitle} ${utilStyles.breakWord}`}>
      {content.map((sub, i) => (
        <span key={i}>
          {sub.linkTo
            ? <a className={`${typography.linkPebble} ${typography.bold} ${typography.bodyS}`} href={sub.linkTo}>{sub.text}</a>
            : <span className={typography.pebble}>{sub.text}</span>}
          {withCommas && content.length > 1 && i !== content.length - 1 ? ',' : null}
          &nbsp;
        </span>
      ))}
    </div>
  );
};

SubTitle.propTypes = {
  classList: PropTypes.shape({ subTitle: PropTypes.string }),
  content: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    linkTo: PropTypes.string,
  })),
  withCommas: PropTypes.bool,
};

SubTitle.defaultProps = {
  classList: {},
  content: [],
  withCommas: true,
};

export default SubTitle;
