import React from 'react';
import PropTypes from 'prop-types';

import ContestCard from '../../client/cards/contest_card';
import DummyProjectCard from '../../client/cards/project_card/DummyCard';
import HorizontalScrollBox from '../../client/wrappers/horizontal_scroll_box';
import LazyComponent from '../../client/wrappers/lazy_component';
import ProjectCard from '../../client/cards/project_card';

import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';
import styles from './contests_page.css';

/**
 * Helpers
 */
const _getProjectsForContest = (contest, projectMap) => {
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  if (projectMap.hasOwnProperty(contest.id)) return projectMap[contest.id];

  return [];
};

/**
 * Views
 */
const ProjectsListView = ({ isBusy, projects }) => {
  if (!isBusy && !projects.length) return null;
  if (isBusy && !projects.length) return (<ProjectsListLoaderView />);

  return projects.map((project, i) => (
    <ProjectCard
      key={project.id}
      cardBorder={true}
      classList={{ wrapper: styles.recentCard }}
      imageProps={{ // This will force all gifs to a static png
        forceImg: true,
        queryParams: { fm: 'png' },
      }}
      itemIndex={i}
      project={project}
      usePortal={true}
    />
  ));
};

const ProjectsListLoaderView = () => [...Array(3).keys()].map((i) => (
  <DummyProjectCard key={i} classList={{ wrapper: styles.recentCard }} />
));

const RecentContestRowView = ({ contest, isBusy, projects }) => (
  <HorizontalScrollBox classList={{ wrapper: layout.marginBottom30 }}>
    <ContestCard classList={{ wrapper: styles.recentCard }} config={{ btnSize: 'md' }} item={contest} />
    <ProjectsListView isBusy={isBusy} projects={projects} />
  </HorizontalScrollBox>
);

/**
 * Main component
 */
const RecentContestsList = ({ contests, getProjectsForRecent, isBusy, projectsForContests }) => (
  <div>
    <h3 className={`${layout.marginBottom22} ${typography.h3}`}>Recent</h3>

    <LazyComponent onReveal={getProjectsForRecent}>
      <div className={layout.marginBottom10}>
        {contests.map((contest) => (
          <RecentContestRowView
            key={contest.id}
            contest={contest}
            isBusy={isBusy}
            projects={_getProjectsForContest(contest, projectsForContests)}
          />
        ))}
      </div>
    </LazyComponent>
  </div>
);

RecentContestsList.propTypes = {
  contests: PropTypes.arrayOf(PropTypes.shape({
    cover_image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    sponsors: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    })).isRequired,
    status: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    winning_projects_ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  })),
  getProjectsForRecent: PropTypes.func.isRequired,
  isBusy: PropTypes.bool.isRequired,
  projectsForContests: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.shape({
      content_type: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      contest_winner: PropTypes.bool,
      cover_image_url: PropTypes.string,
      difficulty: PropTypes.string,
      guest_name: PropTypes.string,
      hid: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      one_liner: PropTypes.string,
      stats: PropTypes.shape({
        respects: PropTypes.number,
        views: PropTypes.number,
      }),
      team: PropTypes.shape({
        members: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string,
          url: PropTypes.string,
        })),
        name: PropTypes.string,
      }),
      url: PropTypes.string,
    })),
  ).isRequired,
};

export default RecentContestsList;
