import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Router, { history, initCurrentPath, Route, transition } from '../../../reusable_components/Router';

import Bookmarks from '../bookmarks';
import Events from '../events';
import Home from '../home';
import Navbar from '../navbar';
import Notifications from '../notifications';
import ProfileForm from '../profile_form';
import Projects from '../projects';

import SeoHandler from '../../../../services/seo_handler';
import seoConfig from '../seoConfig';

import urlService from '../../../../services/url_service';

function getRoutes(hiddenRoutes) {
  const defaults = [
    { href: '/', name: 'Dashboard' },
    { href: '/profile', name: 'Edit Profile' },
    {
      href: '/projects', name: 'Projects', dropdown: [
        { href: '/projects/drafts', name: 'Drafts' },
        { href: '/projects/public', name: 'Public Projects' },
        { href: '/projects/guest', name: 'Guest Projects' },
        { href: '/projects/new', name: 'Create a new project', redirect: true },
      ],
    },
    { href: '/events', name: 'Events' },
    { href: '/bookmarks', name: 'Bookmarks' },
    { href: '/notifications', name: 'Notifications' },
    { href: '/users/edit', name: 'Account', redirect: true },
  ];

  return defaults.reduce((memo, route) => route.dropdown
    ? [...memo, { ...route, dropdown: route.dropdown.filter((subroute) => !hiddenRoutes[subroute.href]) }]
    : !hiddenRoutes[route.href]
      ? [...memo, route]
      : memo, []);
}

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      basePath: props.pathHelpers.basePath,
      initPath: initCurrentPath(props.pathHelpers.fullPath, props.pathHelpers.basePath),
    };

    this.handleLocationChange = this.handleLocationChange.bind(this);

    this.seoHandler = new SeoHandler({ config: seoConfig });
    urlService.setRootPath(props.pathHelpers.rootPath);
  }

  componentDidMount() {
    this.seoHandler.reportView({ path: this.state.initPath });
  }

  handleLocationChange(location, action) {
    this.seoHandler.reportView({ path: location.pathname });
  }

  render() {
    const hiddenRoutes = {
      '/projects/guest': this.props.hide_guest_projects,
      '/events': this.props.isWhitelabel,
    };
    const routes = getRoutes(hiddenRoutes);

    return (
      <Router
        basePath={this.state.basePath}
        initPath={this.state.initPath}
        initializeStoreFn={(ctx) => ctx.store.set('showPrompt', false)}
        onUpdate={this.handleLocationChange}
      >
        <Navbar
          basePath={this.state.basePath}
          initPath={this.state.initPath}
          rootPath={this.props.pathHelpers.rootPath}
          routes={routes}
          store={{}}
          transition={(path, store) => transition(history, path, store.get('showPrompt'))}
        />
        <Route component={Home} path="/" routerProps={{ currentUser: this.props.currentUser, isWhitelabel: this.props.isWhitelabel }} />
        <Route component={ProfileForm} path="profile" routerProps={{ currentUser: this.props.currentUser, imageResources: this.props.imageResources }} />
        <Route component={Projects} path="projects" routerProps={{ currentUser: this.props.currentUser, isRoot: true }} />
        <Route component={Projects} path="projects/drafts" routerProps={{ currentUser: this.props.currentUser }} />
        <Route component={Projects} path="projects/public" routerProps={{ currentUser: this.props.currentUser }} />
        <Route component={Projects} path="projects/guest" routerProps={{ currentUser: this.props.currentUser }} />
        <Route component={Events} path="events" routerProps={{ currentUser: this.props.currentUser }} />
        <Route component={Bookmarks} path="bookmarks" routerProps={{ currentUser: this.props.currentUser, imageURLs: this.props.imageURLs }} />
        <Route component={Notifications} path="notifications" routerProps={{ currentUser: this.props.currentUser }} />
      </Router>
    );
  }
}

Dashboard.propTypes = {
  currentUser: PropTypes.shape({
    available_for_hire: PropTypes.bool,
    avatar: PropTypes.shape({
      file: PropTypes.shape({ url: PropTypes.string }),
      id: PropTypes.number,
    }),
    bio: PropTypes.string,
    city: PropTypes.string,
    country_iso2: PropTypes.string,
    email: PropTypes.string,
    hourly_rate: PropTypes.number,
    id: PropTypes.number,
    interest: PropTypes.array,
    name: PropTypes.string,
    skills: PropTypes.array,
    state: PropTypes.string,
    stats: PropTypes.shape({ projects: PropTypes.number }),
    user_name: PropTypes.string,
    website: PropTypes.string,
  }),
  imageResources: PropTypes.shape({
    aws_key: PropTypes.string,
    s3_url: PropTypes.string,
  }),
  imageURLs: PropTypes.shape({
    bookmarkCoverDefault: PropTypes.shape({
      x1: PropTypes.string.isRequired,
      x2: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  isWhitelabel: PropTypes.bool,
  pathHelpers: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
    fullPath: PropTypes.string.isRequired,
    rootPath: PropTypes.string.isRequired,
  }).isRequired,
};

Dashboard.defaultProps = {
  currentUser: {},
  imageResources: {},
  isWhitelabel: false,
};

export default Dashboard;
