import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { graphQuery } from '../../requests/graphql';
import errorHandler from '../../services/error_handler';

import ActiveContestsSection from './ActiveContestsSection';
import ContestCTABanner from './ContestCTABanner';
import PastContestsList from './PastContestsList';
import RecentContestsList from './RecentContestsList';

import { isBlank } from '../../utility/types';

import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';

const DEFAULT_METADATA = {
  current_page: 1,
  next_page: null,
  per_page: 8,
  prev_page: null,
};
const PAST_CONTEST_PAGE_1_OFFSET = 3;

class ContestsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isBusy: false,
      metadata: DEFAULT_METADATA,
      pastContests: [],
      projectsForRecentContests: {},
      workers: [], // ['past, recent']
    };

    this.getPaginatedPastContests = this.getPaginatedPastContests.bind(this);
    this.getProjectsForRecent = this.getProjectsForRecent.bind(this);
  }

  /**
   * Methods
   */
  getPaginatedPastContests() {
    this.setState((state) => ({ workers: this._addWorker(state, 'past') }));

    return graphQuery({ t: 'get_past_challenges' }, {
      offset: PAST_CONTEST_PAGE_1_OFFSET,
      page: (this.state.metadata.next_page || 1),
      per_page: this.state.metadata.per_page,
    })
      .then(({ past_contests }) => this.setState((state) => ({
        pastContests: state.pastContests.concat(past_contests.records),
        metadata: past_contests.metadata,
        workers: this._removeWorker(state, 'past'),
      })))
      .catch((err) => {
        // Let hang in loader state if the request fails.
        errorHandler('ContestsPage handlePaginatedPastContests', err);
      });
  }

  getProjectsForRecent() {
    this.setState((state) => ({ workers: this._addWorker(state, 'recent') }));

    return graphQuery({ t: 'get_projects_by_id' }, { ids: this.props.recent.map((c) => c.winning_projects_ids).flat() })
      .then(({ projects }) => this.setState((state) => ({
        projectsForRecentContests: this._createContestToProjectMap(this.props.recent, projects),
        workers: this._removeWorker(state, 'recent'),
      })))
      .catch((err) => {
        // Let hang in loader state if the request fails.
        errorHandler('ContestsPage getProjectsForRecent', err);
      });
  }

  /**
   * Helpers
   */
  _addWorker(state, key) {
    if (state.workers.includes(key)) return state;

    return state.workers.concat(key);
  }

  _removeWorker(state, key) {
    if (!state.workers.includes(key)) return state;

    return state.workers.filter((k) => k !== key);
  }

  _createContestToProjectMap(contests, projects) {
    if (isBlank(projects)) return {};

    return contests.reduce((acc, contest) => {
      acc[contest.id] = projects.filter((project) => contest.winning_projects_ids.includes(project.id));

      return acc;
    }, {});
  }

  render() {
    return (
      <div className={`${layout.fullWidth}`}>
        <div className={`${layout.flexJustifyCenter} ${layout.paddingTop30} ${layout.paddingLeft15} ${layout.paddingRight15} ${layout.marginBottom30}`}>
          <div className={`${layout.wrapper1170}`}>
            <h1 className={`${typography.h1} ${layout.marginBottom45}`}>Contests</h1>

            <ActiveContestsSection communityContests={this.props.community} partnerContests={this.props.partner} />

            <ContestCTABanner url={this.props.cta_banner} />

            <RecentContestsList
              contests={this.props.recent}
              getProjectsForRecent={this.getProjectsForRecent}
              isBusy={this.state.workers.includes('recent')}
              projectsForContests={this.state.projectsForRecentContests}
            />
          </div>
        </div>

        <PastContestsList
          contests={this.state.pastContests}
          getPaginatedPastContests={this.getPaginatedPastContests}
          isBusy={this.state.workers.includes('past')}
          metadata={this.state.metadata}
        />
      </div>
    );
  }
}

ContestsPage.propTypes = {
  community: PropTypes.arrayOf(PropTypes.shape({
    cover_image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    disable_projects_tab: PropTypes.bool.isRequired,
    end_date_in_pdt: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    is_registration_open: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    prize: PropTypes.shape({
      cash_value: PropTypes.number,
      category: PropTypes.shape({ name: PropTypes.string }).isRequired,
      name: PropTypes.string.isRequired,
      icon_urls: PropTypes.shape({
        x1: PropTypes.string.isRequired,
        x2: PropTypes.string.isRequired,
      }).isRequired,
      image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    }).isRequired,
    sponsors: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    })).isRequired,
    start_date_in_pdt: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    submissions_url: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })).isRequired,
  cta_banner: PropTypes.string.isRequired,
  partner: PropTypes.arrayOf(PropTypes.shape({
    cover_image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    disable_projects_tab: PropTypes.bool.isRequired,
    end_date_in_pdt: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    is_registration_open: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    prize: PropTypes.shape({
      cash_value: PropTypes.number,
      category: PropTypes.shape({ name: PropTypes.string }).isRequired,
      name: PropTypes.string.isRequired,
      icon_urls: PropTypes.shape({
        x1: PropTypes.string.isRequired,
        x2: PropTypes.string.isRequired,
      }).isRequired,
      image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    }).isRequired,
    sponsors: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    })).isRequired,
    start_date_in_pdt: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    submissions_url: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })).isRequired,
  recent: PropTypes.arrayOf(PropTypes.shape({
    cover_image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    sponsors: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    })).isRequired,
    status: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    winning_projects_ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  })).isRequired,
};

export default ContestsPage;
