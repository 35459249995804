import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TransitionGroup from 'react-transition-group/TransitionGroup';

import AddProjectPanel from './AddProjectPanel';
import CustomIcon from '../../../../client/icon/custom';
import JumboButton from '../../../../client/buttons/jumbo';
import MaxHeightTransition from '../../../../client/transitions/max_height';
import SubmissionCard from './submission_card';

import { getInObj } from '../../../../utility/accessors';

import { ENTRY, IDEA, IN_PROGRESS } from '../../constants';

import layout from '../../../../styles/global_ui/layout.css';
import styles from './side_panel.css';

class EntryManager extends Component {
  constructor(props) {
    super(props);

    this.state = { showProjectPanel: false };

    this.__dismissAddProjectPanelHook = this.__dismissAddProjectPanelHook.bind(this);
    this.createEntry = this.createEntry.bind(this);
    this.dismissAddProjectPanel = this.dismissAddProjectPanel.bind(this);
    this.summonAddProjectPanel = this.summonAddProjectPanel.bind(this);
  }

  /**
   * Hooks
   */
  __dismissAddProjectPanelHook() {
    this.dismissAddProjectPanel();
  }

  /**
   * Methods
   */
  createEntry(id) {
    return this.props.createEntry(id, this.__dismissAddProjectPanelHook);
  }

  dismissAddProjectPanel() {
    this.setState({ showProjectPanel: false });
  }

  summonAddProjectPanel() {
    if (this.state.showProjectPanel) return;
    this.setState({ showProjectPanel: true });
  }

  /**
   * Helpers
   */
  _shouldShowAddProjectButtons() {
    const { allowMultipleEntries, contestStatus, user } = this.props;
    const contestInProgress = contestStatus === IN_PROGRESS;
    const userCanSubmitMoreEntries = user.entries.length === 0 || allowMultipleEntries;

    return contestInProgress && userCanSubmitMoreEntries;
  }

  /**
   * Views
   */
  _getRegisteredView() {
    const {
      canEditSubmission,
      faqPath,
      getUserProjects,
      isBusy,
      newProjectPath,
      resubmitEntry,
      serverErrors,
      user,
      withdrawEntryOrIdea,
    } = this.props;

    return (
      <TransitionGroup appear={true}>
        {this._shouldShowAddProjectButtons()
        && (
          <MaxHeightTransition key="addProjectButtons" className={styles.panelRow} maxHeight={200}>
            <div className={layout.flex}>
              <JumboButton
                anchor={true}
                className={styles.addProjectButton}
                href={newProjectPath}
                icon={<CustomIcon name="new-project" />}
                text="Create new project"
              />
              <JumboButton
                className={styles.addProjectButton}
                disabled={this.state.showProjectPanel}
                icon={<CustomIcon name="existing-project" />}
                onClick={this.summonAddProjectPanel}
                text="Add existing project"
              />
            </div>
          </MaxHeightTransition>
        )}
        {this.state.showProjectPanel
        && (
          <MaxHeightTransition key="addProjectPanel" className={styles.panelRow} maxHeight={250}>
            <AddProjectPanel
              createEntry={this.createEntry}
              dismiss={this.dismissAddProjectPanel}
              getUserProjects={getUserProjects}
              isBusy={isBusy}
              serverError={getInObj(['createEntry'], serverErrors)}
            />
          </MaxHeightTransition>
        )}
        {user.entries.map((item) => (
          <MaxHeightTransition key={`${ENTRY}-${item.id}`} className={styles.panelRow} maxHeight={250}>
            <SubmissionCard
              canEdit={canEditSubmission[ENTRY]}
              faqPath={faqPath}
              isBusy={isBusy}
              item={item}
              resubmitEntry={resubmitEntry}
              type={ENTRY}
              withdraw={withdrawEntryOrIdea}
            />
          </MaxHeightTransition>
        ))}
        {user.ideas.map((item) => (
          <MaxHeightTransition key={`${IDEA}-${item.id}`} className={styles.panelRow} maxHeight={250}>
            <SubmissionCard
              canEdit={canEditSubmission[IDEA]}
              faqPath={faqPath}
              item={item}
              type={IDEA}
              withdraw={withdrawEntryOrIdea}
            />
          </MaxHeightTransition>
        ))}
      </TransitionGroup>
    );
  }

  render() {
    return this.props.user.isRegistered
      ? this._getRegisteredView()
      : <TransitionGroup />;
  }
}

EntryManager.propTypes = {
  allowMultipleEntries: PropTypes.bool.isRequired,
  canEditSubmission: PropTypes.shape({
    [ENTRY]: PropTypes.bool.isRequired,
    [IDEA]: PropTypes.bool.isRequired,
  }).isRequired,
  contestStatus: PropTypes.string,
  createEntry: PropTypes.func.isRequired,
  faqPath: PropTypes.string.isRequired,
  getUserProjects: PropTypes.func.isRequired,
  initialized: PropTypes.bool.isRequired,
  isBusy: PropTypes.bool.isRequired,
  newProjectPath: PropTypes.string.isRequired,
  resubmitEntry: PropTypes.func.isRequired,
  serverErrors: PropTypes.shape({
    createEntry: PropTypes.string,
    deleteEntry: PropTypes.string,
  }),
  user: PropTypes.shape({
    entries: PropTypes.arrayOf(PropTypes.shape({
      can_be_resubmitted: PropTypes.bool,
      id: PropTypes.number,
      moderator_feedback: PropTypes.string,
      project: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        url: PropTypes.string,
      }),
      status: PropTypes.string,
      updated_at: PropTypes.string,
    })).isRequired,
    ideas: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      status: PropTypes.string,
      updated_at: PropTypes.string,
      url: PropTypes.string,
    })).isRequired,
    isRegistered: PropTypes.bool.isRequired,
    projects: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })),
    registration: PropTypes.shape({
      challenge: PropTypes.shape({
        activate_email_sharing: PropTypes.bool,
        after_registration_tweet: PropTypes.string,
        email_sharing_label: PropTypes.string,
        facebook_url: PropTypes.string,
        reddit_url: PropTypes.string,
        twitter_url: PropTypes.string,
      }),
      id: PropTypes.number,
    }),
  }).isRequired,
  withdrawEntryOrIdea: PropTypes.func.isRequired,
};

EntryManager.defaultProps = {
  contestStatus: null,
  serverErrors: null,
};

export default EntryManager;
