import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Breadcrumbs from './Breadcrumbs';
import ErrorPage from '../../../client/reusable_components/Router/ErrorPage';
import IdeaView from './IdeaView';
import JudgingPanel from './judging_panel';

import SeoHandler from '../../../services/seo_handler';
import seoConfig from '../seoConfig';

import { cancelablePromise } from '../../../utility/promises';
import createHistory from '../../../client/reusable_components/Router/history';
import errorHandler from '../../../services/error_handler';
import { getInObj } from '../../../utility/accessors';
import { graphQuery } from '../../../requests/graphql';
import { windowLocationRedirect, windowLocationReload } from '../../../services/window';

import layoutStyles from '../../../styles/global_ui/layout.css';
import overlayStyles from '../../../styles/common-overlay.css';
import styles from './idea_page.css';

class IdeaPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      idea: null,
      isBusy: true,
    };

    this.goToBasePath = this.goToBasePath.bind(this);
    this.handleBreadcrumbClick = this.handleBreadcrumbClick.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);

    this.basePath = props.pathHelpers.basePath;
    this.history = createHistory(this.basePath);

    this.seoHandler = new SeoHandler({ config: seoConfig });

    this._activePromise;
    this.unlisten;
  }

  componentDidMount() {
    this.unlisten = this.history.listen(this.handleLocationChange);
    this._fetchIdea(this.props.initIdeaID, 'replace');
  }

  componentWillUnmount() {
    this._cancelActivePromise();
    this.unlisten();
  }

  /**
   * Initializers
   */

  _fetchIdea(id, historyAction = null) {
    this.setState({ isBusy: true });

    this._cancelActivePromise(); // cancel any previous request so requests don't stack and mess with state
    this._activePromise = cancelablePromise(graphQuery({ t: 'get_challenge_idea' }, { id: parseInt(id), judging: true }));

    return this._activePromise
      .promise
      .then((res) => {
        if (res.promiseCanceled) return;

        const idea = res.paginated_challenge_idea;
        if (!idea) return this._showErrorPage();

        this._showIdea(idea);

        if (historyAction) {
          this.history[historyAction](`/${idea.id}`, { idea });
        }
      })
      .catch((err) => {
        errorHandler(err);
        if (err.promiseCanceled) return;
        this._showErrorPage();
      });
  }

  /**
   * Methods
   */
  goToBasePath() {
    windowLocationRedirect(this.basePath);
  }

  handleBreadcrumbClick(e, id) {
    e.preventDefault();

    return this._fetchIdea(id, 'push');
  }

  handleLocationChange(location, action) {
    /* this is a rare case. If a user reloads the /ideas page while the idea modal is shown,
       it will bring them here, at /idea scene. then If the user tries to go back using
       browser buttons, the browser thinks it's a pushState navigation, and will not load /ideas,
       so we have to reload to display the correct page.
      */
    if (location.pathname === '/') return windowLocationReload();

    // action is "REPLACE" on mount. globalKeenService has already recorded this pageView. no need to do anything
    if (action === 'REPLACE') return;

    const idea = getInObj(['state', 'idea'], location);
    this.seoHandler.reportView({ path: '/ideas/*', data: idea });

    if (action === 'POP') {
      this._cancelActivePromise();
      this._showIdea(idea);
    }
  }

  /**
   * Helpers
   */
  _canCurrentUserJudgeIdea() {
    return (
      (getInObj(['current_user_permissions', 'admin'], this.state.idea) === true)
      || (getInObj(['current_user_permissions', 'moderate'], this.state.idea) === true)
    );
  }

  _cancelActivePromise() {
    if (this._activePromise) this._activePromise.cancel();
  }

  _showErrorPage() {
    this.setState({ error: true, idea: null, isBusy: false });
  }

  _showIdea(idea) {
    this.setState({ error: false, idea, isBusy: false });
  }

  render() {
    const { dashboardURL, hardwareName } = this.props;
    const { error, idea, isBusy } = this.state;

    return (
      <div className={styles.root}>
        {this.state.idea !== null
        && (
          <Fragment>
            <div className={layoutStyles.container}>
              <div className={styles.ideaWrapper}>
                <Breadcrumbs
                  basePath={this.basePath}
                  nextID={idea.next_id}
                  onClick={this.handleBreadcrumbClick}
                  prevID={idea.prev_id}
                />
                <IdeaView key={idea.id} fullPage={true} hardwareName={hardwareName} idea={this.state.idea} />
              </div>
            </div>
            {this._canCurrentUserJudgeIdea()
            && (
              <div className={styles.judgingPanel}>
                <JudgingPanel
                  key={idea.id}
                  dashboardURL={dashboardURL}
                  idea={idea}
                />
              </div>
            )}
          </Fragment>
        )}
        {error
        && (
          <div className={layoutStyles.fullWidth}>
            <ErrorPage basePath={this.basePath} history={this.history} transition={this.goToBasePath} />
          </div>
        )}
        {isBusy
        && (
          <div className={overlayStyles.overlay}>
            <i className="fa fa-3x fa-circle-o-notch fa-spin" />
          </div>
        )}
      </div>
    );
  }
}

IdeaPage.propTypes = {
  dashboardURL: PropTypes.string.isRequired,
  hardwareName: PropTypes.string.isRequired,
  initIdeaID: PropTypes.number.isRequired,
  pathHelpers: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
    fullPath: PropTypes.string.isRequired,
    rootPath: PropTypes.string.isRequired,
  }).isRequired,
};

export default IdeaPage;
