import React, { useState } from 'react';
import PropTypes from 'prop-types';

import LazyImage from '../../client/reusable_components/LazyImage';
import CheckboxCard from '../../client/form_components/checkboxes/card';

import cardStyles from '../../client/cards/cards.css';
import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';
import utilStyles from '../../styles/global_ui/util.css';
import interestStyles from './interests.css';

const InterestsCard = ({ handleUpdate, option }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onChange = async (option) => {
    setIsLoading(true);
    await handleUpdate(option);
    setIsLoading(false);
  };

  return (
    <CheckboxCard
      checkboxStyles={`${interestStyles.container} ${layout.fullHeight}`}
      isChecked={option.checked}
      isDisabled={isLoading}
      name={option.name}
      onChange={onChange}
      value={{ id: option.id, type: option.type }}
      wrapperStyles={layout.flex1}
    >
      <div className={`${layout.flexColumnBetweenCenter} ${layout.fullHeight}
        ${typography.textCenter} ${typography.breakWord} ${interestStyles.card}`}
      >
        <LazyImage
          alt={option.name}
          className={`${utilStyles.border} ${utilStyles.borderRadius} ${cardStyles.lazyImage} ${interestStyles.image}`}
          fit="fill"
          ratio="1:1"
          src={option.image_url || option.avatar_url || ''}
          width={68}
        />

        <p className={`${layout.marginTop22} ${typography.bodyM} ${typography.textTruncate4Lines} ${interestStyles.text}`}>{option.name}</p>
      </div>
    </CheckboxCard>
  );
};

InterestsCard.propTypes = {
  handleUpdate: PropTypes.func.isRequired,
  option: PropTypes.shape({
    avatar_url: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    image_url: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default InterestsCard;
