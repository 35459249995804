import React from 'react';
import PropTypes from 'prop-types';

import AnchorButton from '../../client/buttons/anchor';
import LazyImage from '../../client/reusable_components/LazyImage';

import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';
import utilStyles from '../../styles/global_ui/util.css';
import styles from './contests_page.css';

const ContestCTABanner = ({ url }) => (
  <div className={`${styles.ctaBannerWrapper} ${layout.flex} ${layout.fullWidth} ${utilStyles.boxShadow3} ${utilStyles.borderRadius}`}>
    <div className={`${styles.ctaImageWrapper} ${layout.flexCenterItems} ${layout.hiddenMedDown}`}>
      <LazyImage
        classList={{ root: styles.ctaImage }}
        ratio="476:195"
        src={url}
        width={268}
      />
    </div>
    <div className={`${layout.flexCenterItems} ${layout.flexColumn} ${layout.flexJustifyCenter} ${layout.fullWidth}`}>
      <h4 className={`${layout.marginBottom10} ${typography.h4}`}>
        Want to run your own contest?
      </h4>
      <AnchorButton href="/business">
        Learn more
      </AnchorButton>
    </div>
  </div>
);

ContestCTABanner.propTypes = { url: PropTypes.string.isRequired };

export default ContestCTABanner;
