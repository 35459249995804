import React, { useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import markdownIt from 'markdown-it';
import { Close as DialogClose } from '@radix-ui/react-dialog';
import { Close as PopupClose } from '@radix-ui/react-popover';
import { Model } from 'survey-core';
import { Survey as SurveyJS, ReactElementFactory } from 'survey-react-ui';

import { addToDataLayer } from '../../../services/analytics/google';
import Dialog from '../DialogRadix';
import Popup from '../Popup';
import RadioGroup from './RadioGroup';
import RobotRating from './RobotRating';

import button from '../../../styles/global_ui/buttons';
import layout from '../../../styles/global_ui/layout';
import util from '../../../styles/global_ui/util';
import typography from '../../../styles/global_ui/typography';
import style from './survey.css';

const c = {
  container: `${layout.flexColumn} ${layout.gutter10} ${style.container}`,
  pageRoot: `${layout.flexColumn} ${layout.gutter10} ${layout.marginTop5}`,
  body: `${layout.flexColumn} ${layout.gutter10}`,
  title: `${typography.h3} ${typography.textCenter}`,
  header: `${typography.bodyXS} ${typography.charcoal} ${style.header}`,
  error: `${typography.error} ${typography.bodyXS}`,
  list: `${style.list}`,
  radiogroup: `${layout.flexJustifyCenter} ${layout.gutter10}`,
  textarea: `${style.textarea}`,
  footer: `${util.posRelative}`,
  submitBtn: `${button.xxs}`,
  backBtn: `${button.xxs} ${button.white} ${typography.pebble}`,
  hidden: `${util.displayNone}`,
};

const baseCss = {
  body: c.pageRoot, // wraps the all questions and navigation
  page: {
    root: c.body, // wraps list of questions
    title: c.title,
  },
  question: {
    header: c.header,
    hasError: c.error,
    titleBar: c.hidden, // hide action buttons for questions. eg. "clear", "more", "..."
  },
  rating: { rootWrappable: c.list }, // wraps the options for ratings question
  radiogroup: { root: c.radiogroup }, // wraps the options for radio group question (allows inline options)
  comment: { content: c.textarea, onError: typography.charcoal },
  footer: c.footer, // wraps the submit prev/next buttons
  navigation: { complete: c.submitBtn, next: c.submitBtn, prev: c.backBtn, start: c.submitBtn },
};

const progressBarStyle = 'position: absolute; top: calc(50% - 2px); display: flex; justify-content: center; width: 100%; gap: 5px; z-index: -1;';

const SurveyPopup = ({ asDialog, surveyCss, popup, onSubmit, surveyJson }) => {
  const { onOpenChange } = popup;
  const closeButton = useRef();
  const pageCountRef = useRef();
  const footerRef = useRef();
  const progressBarRef = useRef();

  const createProgressBar = useCallback(({ currentPageNo, title, isShowStartingPage }) => {
    progressBarRef.current = document.createElement('div');
    const progressBar = progressBarRef.current;
    progressBar.setAttribute('id', `progress-bar-${title}`);
    footerRef.current.appendChild(progressBar);
    // when there is a "start" page, hide the progress bar until the "first" real page.
    isShowStartingPage
      ? progressBar.style.display = 'none'
      : progressBar.style.cssText = progressBarStyle;

    for (let i = 0; i < pageCountRef.current; i++) {
      const dot = document.createElement('div');
      progressBar.appendChild(dot);
      dot.style.cssText = `border: ${i === currentPageNo ? '#2E9FE6' : '#D8D8D8'} 2px solid; background-color: ${i === currentPageNo ? '#2E9FE6' : '#D8D8D8'}; width: 5px;height: 5px; border-radius: 50%;`;
      dot.setAttribute('data-testid', `progress-indicator-${i}`);
    }
  }, []);

  const updateFooter = useCallback(({ currentPageNo, isGoingBackward, isLastPage }) => {
    // the footer container has a few nested elements we won't show. Change flex layout depending on which page we currently are on.

    // pages are numbered on 0 index
    if (currentPageNo === 1) { // set when they progress to the second page
      document.getElementById('sv-nav-start').style.display = 'none';
      document.getElementById('sv-nav-preview').style.display = 'none';
      document.getElementById('sv-nav-complete').style.display = isLastPage ? 'block' : 'none';
      footerRef.current.style['justify-content'] = 'space-between';
    } else if (currentPageNo === 0) { // handle backwards navigation
      footerRef.current.style['justify-content'] = 'end';
    } else if (isGoingBackward && !isLastPage) {
      document.getElementById('sv-nav-complete').style.display = 'none';
    } else if (isLastPage) {
      document.getElementById('sv-nav-complete').style.display = 'block';
    }
  }, []);

  const updateProgressBar = useCallback(({ currentPageNo, isShowStartingPage, shouldRecreateDots, surveyTitle, visiblePageCount }) => {
    if (shouldRecreateDots) {
      const progressBar = progressBarRef.current;
      progressBar.parentNode.removeChild(progressBar);
      progressBar.current = null;
      pageCountRef.current = visiblePageCount - (isShowStartingPage ? 1 : 0);
      createProgressBar({ currentPageNo, title: surveyTitle, isShowStartingPage });
    } else {
      const dots = Array.from(progressBarRef.current.children);
      dots.map((dot, i) => {
        dot.style.cssText = `border: ${i === currentPageNo ? '#2E9FE6' : '#D8D8D8'} 2px solid; background-color: ${i === currentPageNo ? '#2E9FE6' : '#D8D8D8'}; width: 5px;height: 5px; border-radius: 50%;`;
      });
    }
  }, [createProgressBar]);

  const handleConvertMarkdown = useCallback((_survey, options) => {
    const md = markdownIt();
    const html = md.render(options.text);
    // Strip out the added paragraph tags `<p>question label with **markdown**?</p>` -> `question label with **markdown**?`. This is needed to keep the required asterisk inline.
    const result = html.slice(3, html.length - 5);
    options.html = result;
  }, []);

  const handleRender = useCallback((survey) => {
    const { visiblePageCount, currentPageNo, isShowStartingPage, title } = survey;
    pageCountRef.current = visiblePageCount;
    // the footer container has a few nested elements we won't show. Change flex layout if it is a single page
    const containerEl = document.getElementById(survey.title);
    const footer = containerEl.querySelectorAll('.sd-action-bar');
    footerRef.current = footer[footer.length - 1];

    if (visiblePageCount <= 1 || isShowStartingPage) {
      footerRef.current.style.display = 'flex';
      footerRef.current.style['justify-content'] = 'center';

      if (!isShowStartingPage) return;
    } else {
      footerRef.current.style.display = 'flex';
      footerRef.current.style['justify-content'] = 'end';
    }
    createProgressBar({ currentPageNo, title, isShowStartingPage });
  }, [createProgressBar]);

  const handleStart = useCallback((survey) => {
    // when there is a "start" page, show the progress bar on the "first" real page.
    if (!survey.firstPageIsStarted) {
      return;
    }

    const { title } = survey;

    addToDataLayer({
      event: 'Survey started',
      survey: title,
      version: surveyJson.version,
    });

    footerRef.current.style['justify-content'] = 'end';
    progressBarRef.current.style.cssText = progressBarStyle;
  }, [surveyJson.version]);

  const handlePageChange = useCallback((survey, options) => {
    const { currentPage, currentPageNo, visiblePageCount, title, isShowStartingPage, isLastPage } = survey;
    const { isGoingBackward } = options;

    !isGoingBackward && addToDataLayer({
      event: 'Survey page changed',
      survey: title,
      version: surveyJson.version,
      currentPageNo,
      currentPage: currentPage.name,
    });

    const shouldRecreateDots = pageCountRef.current !== visiblePageCount;
    updateFooter({ currentPageNo, isGoingBackward, isLastPage });
    updateProgressBar({ currentPageNo, isShowStartingPage, shouldRecreateDots, surveyTitle: title, visiblePageCount });
  }, [surveyJson.version, updateFooter, updateProgressBar]);

  const surveyComplete = useCallback((survey) => {
    addToDataLayer({
      event: 'Survey submitted',
      survey: survey.title,
      version: surveyJson.version,
    });
    onSubmit({ content: survey.data });
  }, [onSubmit, surveyJson.version]);

  const handleProcessHtml = useCallback((_survey, options) => {
    // When we exit the survey, ie. it is completed, we want to show the close button. This button is not apart of the survey, but a child of the Popup modal.
    if (options.reason === 'completed') {
      closeButton.current.style.display = 'flex';
      closeButton.current.style['justify-content'] = 'center';
    }
  }, []);

  const handleOpenChange = (isOpening) => {
    if (isOpening) {
      onOpenChange?.(isOpening);
      return;
    }
    // send partially completed survey
    if (survey.state !== 'completed' && Object.keys(survey.data).length) {
      onSubmit({ content: survey.data });
    }
    // reset survey and close
    survey.clear(true, true);
    const event = new Event('closeSurvey');
    document.dispatchEvent(event);

    addToDataLayer({
      event: 'Survey dismissed',
      survey: survey.title,
      version: surveyJson.version,
    });

    onOpenChange?.(isOpening);
  };

  const survey = useMemo(() => {
    if (!popup.open) {
      return null;
    }
    const model = new Model(surveyJson);

    model.css = { ...baseCss, ...surveyCss };

    ReactElementFactory.Instance.registerElement(
      'RobotRating',
      (props) => React.createElement(RobotRating, { ...props, survey: model }),
    );

    ReactElementFactory.Instance.registerElement(
      'RadioGroup',
      (props) => React.createElement(RadioGroup, { ...props, survey: model }),
    );

    addToDataLayer({
      event: 'Survey opened',
      survey: model.title,
      version: surveyJson.version,
    });

    // events will fire in this order, with the exception of onProcessHtml
    model.onTextMarkdown.add(handleConvertMarkdown);
    model.onAfterRenderSurvey.add(handleRender);
    model.onStarted.add(handleStart);
    model.onCurrentPageChanged.add(handlePageChange);
    model.onComplete.add(surveyComplete);
    model.onProcessHtml.add(handleProcessHtml); // we only care about this event when it fires after onComplete.

    return model;
  }, [handleConvertMarkdown, handlePageChange, handleProcessHtml, handleRender, handleStart, popup.open, surveyComplete, surveyCss, surveyJson]);

  return (asDialog
    ? (
      <Dialog
        asModal={false}
        classList={{ container: popup.classList?.container, trigger: `${button.unset} ${popup.classList?.trigger}` }}
        hasClose={popup.hasClose}
        id="surveyJS"
        onInteractOutside={(e) => e.preventDefault()}
        onOpenChange={handleOpenChange}
        open={popup.open}
        title={popup.title}
        trigger={popup.trigger}
      >
        {popup.open
        && (
          <div
            className={c.container}
            id={surveyJson.title}
          >
            <SurveyJS model={survey} />
            <div ref={closeButton} data-testid="popup-close-btn" style={{ display: 'none' }}>
              <DialogClose className={c.submitBtn} name="close-survey">Close</DialogClose>
            </div>
          </div>
        )}
      </Dialog>
      )
    : (
      <Popup
        align={popup.align || 'start'}
        alignOffset={popup.alignOffset}
        classList={{ container: popup.classList?.container, trigger: `${button.unset} ${popup.classList?.trigger}` }}
        collisionPadding={5}
        disabled={popup.disabled}
        hasClose={popup.hasClose}
        id="surveyJS"
        onOpenChange={handleOpenChange}
        open={popup.open}
        side={popup.side || 'bottom'}
        sideOffset={popup.sideOffset}
        trigger={popup.trigger}
        triggerAria={popup.triggerAria}
      >
        {popup.open
        && (
          <div
            className={c.container}
            id={surveyJson.title}
          >
            <SurveyJS model={survey} />
            <div ref={closeButton} data-testid="popup-close-btn" style={{ display: 'none' }}>
              <PopupClose className={c.submitBtn} name="close-survey">Close</PopupClose>
            </div>
          </div>
        )}
      </Popup>
      )
  );
};

export default SurveyPopup;

SurveyPopup.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  popup: PropTypes.shape({
    align: PropTypes.string,
    alignOffset: PropTypes.number,
    classList: PropTypes.shape({ container: PropTypes.string, trigger: PropTypes.string }),
    hasClose: PropTypes.bool,
    open: PropTypes.bool,
    onOpenChange: PropTypes.func,
    side: PropTypes.string,
    sideOffset: PropTypes.number,
    title: PropTypes.string,
    trigger: PropTypes.node.isRequired,
  }),
  surveyCss: PropTypes.shape({}), // target surveyJS elements directly
  surveyJson: PropTypes.shape({}).isRequired,
};
