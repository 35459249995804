/* eslint-disable react/jsx-key */

import React from 'react';
import PropTypes from 'prop-types';

import AnchorButton from '../../../../../client/buttons/anchor';
import Icon from '../../../../../client/icon';
import Link from '../../../../../client/link';
import SubmissionMenu from './SubmissionMenu';
import SubmissionStatus from './SubmissionStatus';

import arrayToSentence from '../../../../../client/utility_components/arrayToSentence';
import { ENTRY, IDEA, APPROVED, DRAFT, INCOMPLETE, REJECTED, WON } from '../../../constants';
import { CONTEST_DATE_AT_TIME_FORMAT, formatContestTimestampPT } from '../../../../../utility/time';

import layout from '../../../../../styles/global_ui/layout.css';
import typography from '../../../../../styles/global_ui/typography.css';
import panelStyles from '../side_panel.css';
import styles from './submission_card.css';

/**
 * Helpers
 */
// We won't always need to ship prizes, (some can be virtual) BUT we always want to verify an address before we give away any prizes.
const _getAddressButtonProps = (item) => (
  !item.is_prize_address_present ? { href: item.edit_address_url, text: `${item.can_edit_address ? 'Verify' : 'View'} shipping address` } : null
);

const _getIncompleteEntryButtonProps = (item, resubmitEntry) => (
  item.can_be_resubmitted
    ? {
        href: 'javascript:void(0)',
        onClick: () => resubmitEntry(item),
        text: 'Resubmit project',
      }
    : {
        href: item.project.edit_url,
        text: 'Update project',
      }
);

const _getEntryButtonProps = (canEdit, item, resubmitEntry) => {
  switch (item.status) {
    case DRAFT:
      return canEdit ? { href: item.submit_url, text: 'Review and submit project' } : null;
    case INCOMPLETE:
    case REJECTED:
      return canEdit ? _getIncompleteEntryButtonProps(item, resubmitEntry) : null;
    case WON:
      return _getAddressButtonProps(item);
    default:
      return null;
  }
};

const _getIdeaButtonProps = (canEdit, item) => {
  switch (item.status) {
    case DRAFT:
      return canEdit ? { href: `${item.url}/edit`, text: 'Finish idea submission' } : null;
    case APPROVED:
      return item.requires_address ? { href: item.edit_address_url, text: `${item.can_edit_address ? 'Verify' : 'View'} shipping address` } : null;

    default:
      return null;
  }
};
const _getDraftMessage = (type) => (
  type === ENTRY ? 'Not submitted yet' : 'In progress'
);

const _getLastUpdated = (item, type) => (
  item.status === DRAFT
    ? _getDraftMessage(type)
    : `Last updated: ${formatContestTimestampPT(item.updated_at_pt, CONTEST_DATE_AT_TIME_FORMAT)}`
);

const _getTypeLabel = (type) => type === ENTRY ? 'Project' : 'Idea';

/**
 * Views
 */
const _getButtonView = ({ canEdit, isBusy, item, resubmitEntry, type }) => {
  const buttonProps = type === ENTRY
    ? _getEntryButtonProps(canEdit, item, resubmitEntry)
    : _getIdeaButtonProps(canEdit, item);
  if (!buttonProps) return null;

  return (
    <AnchorButton
      className={`${layout.fullWidth} ${layout.marginTop15}`}
      disabled={isBusy}
      href={buttonProps.href}
      onClick={buttonProps.onClick}
    >
      {buttonProps.text}
    </AnchorButton>
  );
};

const _getCategoriesView = (categories) => (
  <div className={`${typography.bodyS} ${layout.marginBottom10}`}>
    {'In '}
    {arrayToSentence(categories.map((cat) => <span className={typography.bold}>{cat}</span>))}
  </div>
);

const _getSubmissionMenuView = ({ canEdit, item, type, withdraw }) => canEdit
  ? (
    <SubmissionMenu
      canEdit={canEdit}
      item={item}
      type={type}
      withdraw={withdraw}
    />
    )
  : <Icon name="locked" />;

const SubmissionCard = ({
  canEdit,
  faqPath,
  isBusy,
  item,
  resubmitEntry,
  type,
  withdraw,
}) => (
  <div className={panelStyles.panel}>
    <div className={styles.cardBody}>
      <div>
        <div className={layout.marginBottom5}>
          <h5 className={typography.h5}>
            {`${_getTypeLabel(type)} submission`}
          </h5>
        </div>
        <Link bold={true} className={layout.marginTop5} href={type === ENTRY ? item.project.url : item.url}>
          {type === ENTRY ? item.project.name : item.name}
        </Link>
        {!!item.categories && item.categories.length > 0 && _getCategoriesView(item.categories)}
        <div className={`${typography.bodyS} ${layout.marginTop5}`}>{_getLastUpdated(item, type)}</div>
        <SubmissionStatus faqPath={faqPath} item={item} type={type} />
      </div>
      {_getSubmissionMenuView({ canEdit, item, type, withdraw })}
    </div>
    {_getButtonView({ canEdit, isBusy, item, resubmitEntry, type })}
  </div>
)
;

SubmissionCard.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  faqPath: PropTypes.string.isRequired,
  isBusy: PropTypes.bool,
  item: PropTypes.oneOfType([
    PropTypes.shape({ // ENTRY
      can_be_resubmitted: PropTypes.bool.isRequired,
      categories: PropTypes.arrayOf(PropTypes.string).isRequired,
      edit_address_url: PropTypes.string,
      id: PropTypes.number.isRequired,
      is_prize_address_present: PropTypes.bool,
      moderator_feedback: PropTypes.string,
      prize_requires_shipping: PropTypes.bool,
      project: PropTypes.shape({
        edit_url: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
      status: PropTypes.string.isRequired,
      submit_url: PropTypes.string,
      updated_at: PropTypes.string,
    }),
    PropTypes.shape({ // IDEA
      edit_address_url: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      updated_at: PropTypes.string,
      url: PropTypes.string.isRequired,
    }),
  ]),
  resubmitEntry: PropTypes.func,
  type: PropTypes.oneOf([ENTRY, IDEA]).isRequired,
  withdraw: PropTypes.func,
};

SubmissionCard.defaultProps = {
  isBusy: false,
  resubmitEntry: () => {},
  withdraw: () => {},
};

export default SubmissionCard;
