import PropTypes from 'prop-types';
import React from 'react';

import ContentPlaceholder from '../../reusable_components/ContentPlaceholder';
import Breadcrumbs from './breadcrumbs';
import { transition, history } from '../../reusable_components/Router';

import { labels } from '../projects_section';
import urlService from '../../../services/url_service';

import buttonStyles from '../../../styles/global_ui/buttons.css';
import styles from './page_header.css';

function getPlaceholderType(category) {
  return category === 'drafts'
    ? 'no-draft-projects'
    : 'no-projects';
}

const headerValues = {
  notifications: ({ count }) => ({
    header: (
      <div className={styles.headerInner}>
        <span>Notifications</span>
        <a
          className={`${buttonStyles.sm} ${buttonStyles.tertiary} ${buttonStyles.pill}`}
          href={urlService.url('/users/notifications/edit')}
        >
          Notification settings
        </a>
      </div>),
    subHeader: count === 0
      ? <ContentPlaceholder classList={{ container: styles.noProjectsText }} type="no-notifications" />
      : '',
  }),
  projects: (typeArgs = { category: '', numProjects: 0 }) => {
    const { category, numProjects } = typeArgs;

    return {
      header: `${labels[category].title}${numProjects === null ? '' : ` (${numProjects})`}`,
      subHeader: numProjects === 0
        ? <ContentPlaceholder classList={{ container: styles.noProjectsText }} type={getPlaceholderType(category)} />
        : '',
    };
  },
  home: (typeArgs = { name: '', user_name: '' }) => {
    const { name, user_name } = typeArgs;

    return {
      header: `${name}'s Dashboard`,
      subHeader: <div className={styles.viewProfileLink}><a href={urlService.url(`/${user_name}`)}>View profile</a></div>,
    };
  },
};

const breadcrumbLinks = {
  projects: (typeArgs = { singleCategory: false, category: '' }) => {
    if (!typeArgs.singleCategory) return false;

    return [
      { text: 'Projects', callback: () => transition(history, '/projects') },
      { text: labels[typeArgs.category].title },
    ];
  },
};

const PageHeader = ({ showProjectButton, type, typeArgs }) => {
  const breadcrumbs = breadcrumbLinks[type] ? breadcrumbLinks[type](typeArgs) : false;
  const { header, subHeader } = headerValues[type](typeArgs);

  return (
    <header className={styles.pageHeaderContainer}>
      <div className={styles.pageHeaderWrapper}>
        {breadcrumbs
        && (
          <Breadcrumbs
            classList={{ linkContainer: styles.breadcrumbs }}
            links={breadcrumbs}
          />
        )}
        <span className={styles.pageHeader}>{header}</span>
        {subHeader}
      </div>
      {showProjectButton
      && (
        <a
          className={buttonStyles.md}
          href={urlService.url('/projects/new')}
        >
          Create a new project
        </a>
      )}
    </header>
  );
};

PageHeader.propTypes = {
  showProjectButton: PropTypes.bool,
  type: PropTypes.string.isRequired,
  typeArgs: PropTypes.object.isRequired,
};

PageHeader.defaultProps = { showProjectButton: true };

export default PageHeader;
