import React from 'react';
import PropTypes from 'prop-types';

import AnchorButton from '../../../../client/buttons/anchor';
import ButtonLoader from '../../../../client/buttons/loader';
import LazyImage from '../../../../client/reusable_components/LazyImage';
import Link from '../../../../client/link';

import { appendProtocol } from '../../../../utility/links';

import { CLOSED, IDEA_EDITING_STATUS, LAUNCH_PENDING, OPEN, WINNERS_ANNOUNCED } from '../../constants';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import styleUtils from '../../../../styles/global_ui/util.css';
import styles from './header.css';

// This is currently the first faq item that has to do with ideas
// TODO: organize the faq page better?
const IDEAS_FAQ_ID = 'faq_g_3';

const containerStyles = `${styles.container} ${layout.flex} ${layout.marginTop10} ${layout.marginBottom45} ${layout.paddingBottom45} ${styleUtils.borderBottom}`;
const infoColumnStyles = `${styles.infoColumn} ${layout.flexColumnWrap} ${layout.marginRight60}`;

/**
 * BUTTON
 */
const getOpenStateData = (basePath, currentUserIdea) => {
  if (currentUserIdea && currentUserIdea.status === IDEA_EDITING_STATUS) {
    return { href: `${currentUserIdea.url}/edit`, text: 'Edit your idea' };
  } else if (currentUserIdea) {
    return { href: currentUserIdea.url, text: 'View your application' };
  } else {
    return { href: `${basePath}/new`, text: 'Submit an application' };
  }
};

const getButtonProps = ({ basePath, challenge, currentUserIdea, handleClickPerUserStatus, scrollToSubmissions }) => {
  switch (challenge.free_hardware_status) {
    case OPEN:
      // TODO: Refactor this. Disabling for linter clean-up
      // eslint-disable-next-line no-case-declarations
      const { href, text } = getOpenStateData(basePath, currentUserIdea);

      return {
        text,
        buttonProps: {
          href,
          onClick: handleClickPerUserStatus,
        },
      };
    case WINNERS_ANNOUNCED:
      return {
        text: 'View winning ideas',
        buttonProps: { onClick: (e) => scrollToSubmissions(e, WINNERS_ANNOUNCED) },
      };
    default: // CLOSED or undefined
      return {
        text: 'View submitted ideas',
        buttonProps: { onClick: scrollToSubmissions },
      };
  }
};

const renderButton = (props) => {
  if (!props.initialized) return (<ButtonLoader width="50%" />);
  if (props.challenge.free_hardware_status === LAUNCH_PENDING) return null;

  const { text, buttonProps } = getButtonProps(props);

  return (
    <AnchorButton {...buttonProps}>{text}</AnchorButton>
  );
};

/**
 * CONTENT HELPERS
 */
const isOpenOrLaunchPending = (hardwareStatus) => (hardwareStatus === OPEN || hardwareStatus === LAUNCH_PENDING);

const createContentMethods = {
  info: {
    header: ({ hardwareName, hardwareUrl }) => (
      <span>
        {'Win a '}
        <a className={typography.linkBlue} href={appendProtocol(hardwareUrl)} rel="noopener noreferrer" target="_blank">{hardwareName}</a>
        {' to build your contest submission!'}
      </span>
    ),
    body: ({ challengeUrl }) => (
      <span>
        {'Need hardware to complete your submission? Submit an application to be considered for free hardware. One device maximum per person. Read our '}
        <Link color="Blue" href={`${challengeUrl}/faq#${IDEAS_FAQ_ID}`}>FAQs</Link>
        {' for more details.'}
      </span>
    ),
  },
  prizesRemaining: {
    // Force free_hardware_quantity to 0 in case of leftover for ended contests
    header: ({ hardwareQuantity, hardwareQuantityRemaining, hardwareStatus }) => hardwareStatus !== WINNERS_ANNOUNCED && hardwareQuantityRemaining > 0
      ? `${hardwareQuantityRemaining.toString()} of ${hardwareQuantity.toString()}`
      : '0',
    body: () => <span>devices remaining</span>,
  },
  timeRemaining: {
    header: ({ hardwareStatus, hardwareTimeRemaining }) => hardwareStatus === LAUNCH_PENDING
      ? 'Applications are not open yet'
      : hardwareStatus === CLOSED
        ? 'Applications are closed'
        : hardwareStatus === WINNERS_ANNOUNCED
          ? 'Winners announced'
          : typeof (hardwareTimeRemaining) === 'string'
            ? (
              <span>
                {hardwareTimeRemaining === '0' ? '0 days' : hardwareTimeRemaining}
                {' '}
                remaining
              </span>
              )
            : 'Applications are open',
    body: ({ hardwareStatus, hardwareEndDate, hardwareWinnersAnnouncementDate }) => (hardwareStatus === CLOSED && hardwareWinnersAnnouncementDate !== null)
      ? (<span>{`winners announced on ${hardwareWinnersAnnouncementDate}`}</span>)
      : (hardwareStatus === CLOSED && hardwareWinnersAnnouncementDate === null)
          ? hardwareEndDate
            ? (<span>{`submissions closed on ${hardwareEndDate}`}</span>)
            : 'Devices have been awarded on a first-come, first-served basis'
          : hardwareEndDate
            ? (
              <span>
                {`submissions ${isOpenOrLaunchPending(hardwareStatus) ? 'close' : 'closed'} on ${hardwareEndDate}`}
              </span>
              )
            : isOpenOrLaunchPending(hardwareStatus)
              ? 'Devices will be awarded on a first-come, first-served basis'
              : 'Devices have been awarded on a first-come, first-served basis',
  },
};

/**
 * VIEWS
 */
const bodyTextStyles = (section) => section === 'info' ? `${typography.bodyL} ${layout.marginTop10}` : `${typography.bodyM} ${layout.marginTop5}`;

const renderSection = (section, contentProps) => (
  <div className={layout.marginBottom15}>
    <h2 className={typography.h2}>
      {createContentMethods[section].header(contentProps)}
    </h2>
    <p className={bodyTextStyles(section)}>
      {createContentMethods[section].body(contentProps)}
    </p>
  </div>
);

const IdeasHeader = (props) => {
  // Rename & remap props from challenge + formattedDates simply for readability in createContentMethods
  const contentProps = {
    hardwareName: props.challenge.free_hardware_name,
    hardwareQuantity: props.challenge.free_hardware_quantity,
    hardwareQuantityRemaining: props.challenge.free_hardware_quantity_remaining,
    hardwareStatus: props.challenge.free_hardware_status,
    hardwareUrl: props.challenge.free_hardware_url,
    challengeUrl: props.challenge.url,
    ...props.formattedDates,
  };

  return (
    <div className={containerStyles}>
      <div className={infoColumnStyles}>
        {renderSection('info', contentProps)}
        <div className={layout.marginTop15}>
          {renderSection('prizesRemaining', contentProps)}
          {renderSection('timeRemaining', contentProps)}
        </div>
        <div className={layout.marginTop15}>
          {renderButton(props)}
        </div>
      </div>

      <div className={styles.imageContainer}>
        <LazyImage
          className={styles.image}
          fit="fill"
          queryParams={{ bg: 'fff' }}
          ratio="16:9"
          setStyle={false}
          src={props.challenge.free_hardware_image_url}
          width={740}
        />
      </div>
    </div>
  );
};

IdeasHeader.propTypes = {
  basePath: PropTypes.string.isRequired,
  challenge: PropTypes.shape({
    end_date: PropTypes.string.isRequired,
    free_hardware_end_date: PropTypes.string,
    free_hardware_image_url: PropTypes.string.isRequired,
    free_hardware_name: PropTypes.string.isRequired,
    free_hardware_quantity: PropTypes.number.isRequired,
    free_hardware_quantity_remaining: PropTypes.number.isRequired,
    free_hardware_status: PropTypes.string.isRequired,
    free_hardware_url: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    support_email: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  currentUserIdea: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    url: PropTypes.string,
  }),
  formattedDates: PropTypes.shape({
    hardwareEndDate: PropTypes.string,
    hardwareTimeRemaining: PropTypes.string,
    hardwareWinnersAnnouncementDate: PropTypes.string,
  }).isRequired,
  handleClickPerUserStatus: PropTypes.func.isRequired,
  initialized: PropTypes.bool.isRequired,
  scrollToSubmissions: PropTypes.func.isRequired,
};

IdeasHeader.defaultProps = { currentUserIdea: null };

export default IdeasHeader;
