import PropTypes from 'prop-types';
import React from 'react';

import ProjectsList from '../../../../client/projects/projects_list';
import ContentPlaceholder from '../../../../client/reusable_components/ContentPlaceholder';

import { transition } from '../../../../client/reusable_components/Router';

import buttonStyles from '../../../../styles/global_ui/buttons.css';
import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import styles from './profile.css';

const Projects = ({ currentUser, isProfileOwner, history, profile, projects, toggleRespect }) => (
  <section className={styles.sectionContainer}>
    <header className={styles.sectionHeader}>
      <span className={`${typography.bodyM} ${typography.bold}`}>Projects</span>
    </header>

    {projects.records.length > 0
      ? (
        <ProjectsList
          config={{ controlledRespects: true }}
          currentUser={currentUser}
          horizontal={true}
          records={projects.records.slice(0, 8)}
          toggleRespect={toggleRespect}
        />
        )
      : (
        <ContentPlaceholder
          isProfileOwner={isProfileOwner}
          templateArgs={{ name: profile.name }}
          type="no-public-projects"
        />
        )}
    {projects.metadata.next_page
    && (
      <button
        className={`${buttonStyles.sm} ${buttonStyles.tertiary} ${buttonStyles.pill} ${layout.flexAlignSelfEnd} ${layout.marginTop10}`}
        onClick={() => transition(history, '/projects')}
      >
        View all
      </button>
    )}
  </section>
);

Projects.propTypes = {
  currentUser: PropTypes.object,
  history: PropTypes.object.isRequired,
  isProfileOwner: PropTypes.bool,
  profile: PropTypes.shape({ name: PropTypes.string }),
  projects: PropTypes.shape({}).isRequired,
  toggleRespect: PropTypes.func,
};

Projects.defaultProps = {
  currentUser: {},
  isProfileOwner: false,
  profile: { name: '' },
  toggleRespect: () => Promise.resolve(),
};

export default Projects;
