import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Paginator from '../../../nav_components/paginator';
import Loader from '../../../reusable_components/Router/Loader';
import NotificationsList from './NotificationsList';
import PageHeader from '../../page_header';

import errorHandler from '../../../../services/error_handler';
import notificationsStore from '../../../../services/notifications_store';
import smoothScroll from '../../../utils/smoothScroll';

import layout from '../../../../styles/global_ui/layout.css';

// TODO: When this page is loaded, we should probably reset the new_notification_count and update the Dropdown?
class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      loading: true,
      notifications: notificationsStore.get('notifications'),
    };

    this.handleChange = this.handleChange.bind(this);
    this.paginateTo = this.paginateTo.bind(this);

    this.updateSub = notificationsStore.getChannel().subscribe('notifications.markRead.#', this.handleChange);
  }

  componentDidMount() {
    this.paginateTo(1);
    smoothScroll(0, 300);
  }

  componentWillUnmount() {
    this.updateSub.unsubscribe();
  }

  handleChange(changes) {
    this.setState({ notifications: notificationsStore.get('notifications') });
  }

  paginateTo(pageNum) {
    this.setState({ loading: true });

    return notificationsStore.fetchPage(pageNum, 'FOR_DASHBOARD')
      .then(() => {
        this.setState({
          currentPage: pageNum,
          loading: false,
          notifications: notificationsStore.get('notifications'),
        });
        smoothScroll(0, 0);
      })
      .catch((err) => {
        this.setState({ loading: false });
        errorHandler('Error changing page:', err);
      });
  }

  render() {
    const { notifications, currentPage } = this.state;
    const pageKey = notificationsStore.getPageKey(currentPage);

    return (
      <div className={layout.container}>
        <div className={layout.wrapper960}>
          <PageHeader
            showProjectButton={false}
            type="notifications"
            typeArgs={{ count: notifications && notifications.metadata ? notifications.metadata.total_records : null }}
          />
          {!Array.isArray(notifications.records[pageKey])
            ? <Loader />
            : (
              <div>
                <NotificationsList
                  className={layout.marginBottom30}
                  currentUser={this.props.currentUser}
                  loading={this.state.loading}
                  markRead={notificationsStore.markRead}
                  notifications={notifications.records[pageKey]}
                  pageKey={pageKey}
                />
                {notifications.metadata.total_pages > 1
                && (
                  <Paginator
                    currentPage={currentPage}
                    onClick={this.paginateTo}
                    totalPages={notifications.metadata.total_pages}
                  />
                )}
              </div>
              )}
        </div>
      </div>
    );
  }
}

Notifications.propTypes = {
  currentUser: PropTypes.shape({ id: PropTypes.number }).isRequired,
  notifications: PropTypes.array,
};

Notifications.defaultProps = { notifications: [] };

export default Notifications;
