import React from 'react';
import PropTypes from 'prop-types';

import LazyImage from '../../../client/reusable_components/LazyImage';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import util from '../../../styles/global_ui/util.css';
import styles from './textImageSection.css';

export const c = {
  section: styles.section,
  content: styles.content,
  header: `${typography.h4} ${layout.marginBottom10}`,
  imageWrapper: styles.imageWrapper,
  transparent: util.bgTransparent,
};

const TextImageSection = ({ classList, content, images, imageHeader, title }) => (
  <section className={`${classList?.root ? classList.root : c.section}`}>
    <div className={c.content}>
      <h4 className={c.header}>{title}</h4>
      {content}
    </div>
    <div>
      {imageHeader && imageHeader}
      <div className={`${classList?.imageWrapper ? classList?.imageWrapper : c.imageWrapper}`}>
        {images.map((image, i) => (
          <LazyImage
            key={`${image.src}-${i}`}
            alt={image.alt}
            classList={{ root: c.transparent }}
            fit="fill"
            ratio={image.ratio}
            src={`https://hackster.imgix.net/static/marketing/content/${image.src}.png`}
            width={image.width}
          />
        ))}
      </div>
    </div>
  </section>
);

TextImageSection.propTypes = {
  classList: PropTypes.shape({ imageWrapper: PropTypes.string, root: PropTypes.string }),
  content: PropTypes.node.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape({
    alt: PropTypes.string.isRequired,
    ratio: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
  })),
  title: PropTypes.string.isRequired,
};

TextImageSection.defaultProps = { classList: { imageWrapper: '', root: '' } };

export default TextImageSection;
