import React from 'react';
import PropTypes from 'prop-types';

import ContestSection from './ContestSection.js';
import EventSection from './EventSection.js';
import FinishOnboarding from './FinishOnboarding.js';
import Tour from './Tour.js';
import ProjectSection from './ProjectsSection.js';
import VideoSection from './VideoSection.js';

import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';
import util from '../../styles/global_ui/util.css';
import { ONBOARDING_STEPS } from '../user_onboarding_page/constants.js';

const Section = ({ children, title }) => (
  <section>
    <h2 className={`${typography.bodyM} ${typography.bold} ${typography.charcoal}`}>
      {title}
    </h2>
    {children}
  </section>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

const FeedPage = ({ projects, onboarding_progress, viewed_tips }) => {
  const onboardingCompleted = onboarding_progress.includes('v1')
    || (onboarding_progress.length === ONBOARDING_STEPS.length);

  return (
    <main className={layout.container}>
      <Tour
        viewedTips={viewed_tips}
      />
      <div className={`${layout.wrapper1170}`}>
        {!onboardingCompleted ? <FinishOnboarding onboardingProgress={onboarding_progress.filter((step) => step !== 'v1')} /> : null}
        <div
          className={`
           ${layout.paddingBottom10} ${util.borderBottom} ${layout.flexJustifySpaceBetween} ${layout.flexCenterItems}`}
        >
          <h1 className={`${typography.h1Responsive} ${layout.margin0}`}>My feed</h1>
          <a
            className={`${typography.bodyS} ${typography.bold} ${typography.linkBlue}`}
            href="/onboarding/interests"
            id="feed-update-preferences"
          >
            Update your interests
          </a>
        </div>
        <div className={`${layout.flexColumn} ${layout.gutter30}`}>
          <Section title="Projects">
            <ProjectSection projects={projects.records} />
          </Section>
          <Section title="Videos">
            <VideoSection />
          </Section>
          <Section title="Contests">
            <ContestSection />
          </Section>
          <Section title="Events">
            <EventSection />
          </Section>
        </div>
      </div>
    </main>
  );
};

const metadata = PropTypes.shape({
  current_page: PropTypes.number,
  next_page: PropTypes.number,
  per_page: PropTypes.number,
  prev_page: PropTypes.number,
});

FeedPage.propTypes = {
  projects: PropTypes.shape({
    metadata: metadata,
    records: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default FeedPage;
