import React from 'react';

import LazyImage from '../../client/reusable_components/LazyImage';

import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';
import util from '../../styles/global_ui/util.css';
import styles from './business.css';

const c = {
  section: `${layout.wrapper1170} ${layout.flexColumnCenterCenter} ${layout.marginAuto} ${layout.padding015} ${typography.textCenter} ${styles.community}`,
  header: `${typography.h3Responsive} ${typography.bold} ${typography.hackster} ${layout.margin0} ${layout.marginBottom10}`,
  subHeading: typography.h1Responsive,
  imageWrapper: `${layout.flexJustifyCenter} ${layout.flexCenterItems} ${layout.fullWidth} ${styles.imageWrapper}`,
  transparent: util.bgTransparent,
  image: `${util.bgTransparent} ${layout.fullWidth}`,
};

const Community = () => (
  <section className={c.section}>
    <h2 className={c.header}>Our Community Members</h2>
    <h3 className={c.subHeading}>Real users, real customers, real engagement.</h3>
    <div className={c.imageWrapper}>
      <LazyImage
        alt="Graphic showing Hackster's user and customer base by title. eg. Engineers, Students, Designers, Educators and Researchers"
        classList={{ root: c.transparent }}
        fit="fill"
        ratio="565:467"
        src="https://hackster.imgix.net/static/marketing/business_people_groups.png"
        width={586}
      />
      <LazyImage
        alt="Graphic showing how Hackster can Help your business. eg. Designing / Prototyping a project, Finding inspiration, Learning new skills and techniques, Staying up to date on industry news."
        classList={{ root: c.transparent }}
        fit="fill"
        ratio="830:826"
        src="https://hackster.imgix.net/static/marketing/business_process_circle.png"
        width={415}
      />
    </div>
    <LazyImage
      alt="Map showing percentages of where Hackster's community members are located"
      classList={{ root: c.image }}
      fit="fill"
      ratio="253:128"
      src="https://hackster.imgix.net/static/marketing/business_map.png"
      width={1140}
    />
  </section>

);

export default Community;
