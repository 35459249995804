import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AnchorButton from '../../../../client/buttons/anchor';
import Breadcrumb from '../../../../client/nav_components/breadcrumb';
import Button from '../../../../client/buttons/base';
import Icon from '../../../../client/icon';
import Prompt from '../../../../client/reusable_components/Dialog/Prompt';
import RingSpinner from '../../../../client/spinners/ring';
import SimpleSelect from '../../../../client/form_components/simple_select';
import StatTable from './StatTable';

import { graphMutate } from '../../../../requests/graphql';
import errorHandler from '../../../../services/error_handler';
import { windowLocationRedirect } from '../../../../services/window';
import { timestampToRelativeTime } from '../../../../utility/time';

import inputStyles from '../../../../styles/global_ui/inputs.css';
import layoutStyles from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from '../idea_page.css';

const CHALLENGE_HISTORY_STATS = [
  { key: 'registrations', label: 'Participated' },
  { key: 'ideas_submitted', label: 'Submitted idea' },
  { key: 'ideas_awarded', label: 'Awarded hardware' },
  { key: 'entries_submitted', label: 'Submitted projects' },
  { key: 'entries_won', label: 'Won' },
];

const IDEA_STATUS_OPTIONS = [
  { label: 'Needs review', value: 'PENDING_REVIEW' },
  { label: 'Approved', value: 'APPROVED' },
  { label: 'Rejected', value: 'REJECTED' },
];

const USER_STATUS_LABELS = {
  AVERAGE: '60%',
  BLACKLISTED: '20%',
  PROMISING: '80%',
  SUPER: '99%',
  WORRISOME: '40%',
};

const LoadingIcon = () => <RingSpinner size={16} />;

class IdeaJudgingPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      approvalUpdateState: null,
      approvalStatus: props.idea.status,
      dates: { userAge: this._getUserAge(props.idea.user) },
      deleteState: null,
      dialog: {
        open: false,
        indexes: [],
        records: [],
      },
      isBusy: false,
    };

    this.dismissDialog = this.dismissDialog.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
  }

  /**
   * Methods
   */
  dismissDialog() {
    this.setState({ deleteState: null, dialog: { open: false, indexes: [], records: [] } });
  }

  handleDelete(e) {
    e.preventDefault();
    this.setState({ isBusy: true, deleteState: 'WORKING' });

    return graphMutate({ t: 'delete_contest_idea' }, { id: this.props.idea.id })
      .then(() => windowLocationRedirect(this.props.dashboardURL))
      .catch((err) => {
        errorHandler(err);
        this.setState({ isBusy: false, deleteState: 'ERROR' });
      });
  }

  handleStatusChange({ value }) {
    const oldStatus = this.state.approvalStatus;

    this.setState({ approvalStatus: value, approvalUpdateState: 'SAVING', isBusy: true });

    return graphMutate({ t: 'update_challenge_idea_status' }, { id: this.props.idea.id, status: value })
      .then(() => this.setState({ approvalUpdateState: 'SAVED', isBusy: false }))
      .catch((err) => {
        this.setState({
          approvalUpdateState: 'ERROR',
          approvalStatus: oldStatus,
          isBusy: false,
        });
        errorHandler(err);
      });
  }

  openDialog() {
    this.setState({ dialog: { open: true, indexes: [], records: [] } });
  }

  /**
   * Helpers
   */
  _getChallengeHistoryRows(user) {
    return CHALLENGE_HISTORY_STATS.map(({ key, label }) => (
      [label, user.challenge_stats[key]]),
    );
  }

  _getUpdatedStatusOptions() {
    return IDEA_STATUS_OPTIONS.map((option) => (
      option.value === this.state.approvalStatus ? { ...option, active: true } : option),
    );
  }

  _getUserAccountRows(user) {
    return [
      ['# of projects', user.stats.live_projects],
      ['Signed up', this.state.dates.userAge],
    ];
  }

  _getUserAge(user) {
    return timestampToRelativeTime(user.created_at);
  }

  /**
   * Views
   */
  _getApprovalUpdateStateViews(state) {
    switch (state) {
      case 'SAVING':
        return {
          approvalError: null,
          approvalLabel: (
            <span>
              Saving changes
              <LoadingIcon />
            </span>
          ),
        };

      case 'SAVED':
        return {
          approvalError: null,
          approvalLabel: (
            <span>
              Changes saved
              <Icon name="checkmark" size={12} />
            </span>
          ),
        };

      case 'ERROR':
        return {
          approvalError: this._getErrorView(),
          approvalLabel: null,
        };

      default:
        return { approvalError: null, approvalLabel: null };
    }
  }

  _getErrorView() {
    return (
      <div className={inputStyles.msgWrapper}>
        <div className={inputStyles.error}>Something went wrong. Please try again</div>
      </div>
    );
  }

  render() {
    const { dashboardURL, idea } = this.props;
    const { deleteState, dialog, isBusy } = this.state;
    const { approvalError, approvalLabel } = this._getApprovalUpdateStateViews(this.state.approvalUpdateState);

    return (
      <div className={`${typography.bodyM} ${layoutStyles.fullWidth}`}>
        <Breadcrumb color="Pebble" href={dashboardURL} size="S" text="Back to moderation dashboard" />
        <div className={styles.flexSwitch}>
          <div className={`${inputStyles.inputWrapper} ${styles.flexSwitchChild}`}>
            <div className={layoutStyles.marginBottom10}>
              <span className={`${typography.bodyM} ${typography.bold}`}>Submission status</span>
              <span className={`${layoutStyles.marginLeft15} ${typography.bodyS} ${typography.pebble}`}>
                {approvalLabel}
              </span>
            </div>
            <SimpleSelect
              disabled={this.state.isBusy}
              onSelection={this.handleStatusChange}
              options={this._getUpdatedStatusOptions()}
            />
            {approvalError}
          </div>

          {idea.current_user_permissions.admin
          && (
            <div className={styles.flexSwitchChild}>
              <p className={`${typography.bodyM} ${typography.bold} ${layoutStyles.marginBottom10}`}>Manage submission</p>
              <div className={layoutStyles.flexCenterItems}>
                <AnchorButton className={layoutStyles.marginRight15} colorStyle="secondary" href={`${idea.url}/edit`}>
                  Edit idea
                </AnchorButton>
                <Button colorStyle="danger" disabled={this.state.isBusy} onClick={this.openDialog}>
                  Delete idea
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className={`${utilStyles.borderTop} ${layoutStyles.fullWidth} ${layoutStyles.marginTop45} ${layoutStyles.marginBottom45}`} />
        <div className={`${inputStyles.inputWrapper} ${typography.bodyM}`}>
          <h3 className={`${typography.h3} ${layoutStyles.marginBottom10}`}>User info</h3>
          <div className={layoutStyles.marginBottom10inBottom5}>
            <span className={typography.bold}>{'Likelihood of submitting a project: '}</span>
            <span>{USER_STATUS_LABELS[idea.user.challenge_stats.status]}</span>
          </div>
          <div>
            <span className={typography.bold}>{'Country: '}</span>
            <span>{idea.user.country !== null ? idea.user.country : 'Not available'}</span>
          </div>
          <div>
            <span>* We default to a user&apos;s saved address in their profile. It should also be noted that if a user has multiple shipping addresses, we will show the most recently added. Otherwise, we fallback to their ip.</span>
          </div>
        </div>
        <div className={styles.flexSwitch}>
          <StatTable header="Contest history" rows={this._getChallengeHistoryRows(idea.user)} />
          <StatTable header="Hackster account" rows={this._getUserAccountRows(idea.user)} />
        </div>
        <Prompt
          action={deleteState === 'WORKING' ? <LoadingIcon /> : 'Delete'}
          actionColor="danger"
          body="Once you delete this idea submission it cannot be undone."
          dismiss={this.dismissDialog}
          isBusy={isBusy}
          message={deleteState === 'ERROR' ? this._getErrorView() : null}
          okay={this.handleDelete}
          open={dialog.open}
          title="Are you sure you want to delete this idea submission?"
        />
      </div>
    );
  }
}

IdeaJudgingPanel.propTypes = {
  dashboardURL: PropTypes.string,
  idea: PropTypes.shape({
    application: PropTypes.shape({
      build: PropTypes.string,
      components: PropTypes.string,
      hours_total: PropTypes.number, // admin
      hours_weekly: PropTypes.number, // admin
      owned: PropTypes.string, // admin
      problem: PropTypes.string,
      skills: PropTypes.string, // admin
      solution: PropTypes.string,
    }),
    created_at: PropTypes.string.isRequired,
    current_user_permissions: PropTypes.shape({
      admin: PropTypes.bool.isRequired,
      edit: PropTypes.bool.isRequired,
      moderate: PropTypes.bool.isRequired,
    }).isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    old_html_description: PropTypes.string,
    project: PropTypes.shape({
      cover_image_url: PropTypes.string,
      hid: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
    user: PropTypes.shape({
      avatar_url: PropTypes.string.isRequired,
      challenge_stats: PropTypes.shape({ // admin
        entries_submitted: PropTypes.number,
        entries_won: PropTypes.number,
        ideas_awarded: PropTypes.number,
        ideas_submitted: PropTypes.number,
        registrations: PropTypes.number,
        status: PropTypes.string,
      }),
      country: PropTypes.string, // admin
      created_at: PropTypes.string, // admin
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      stats: PropTypes.shape({ live_projects: PropTypes.number }), // admin
      url: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

IdeaJudgingPanel.defaultProps = { dashboardURL: '' };

export default IdeaJudgingPanel;
