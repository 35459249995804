import React from 'react';
import PropTypes from 'prop-types';
import TransitionGroup from 'react-transition-group/TransitionGroup';

import MaxHeightTransition from '../../../../client/transitions/max_height';

import Button from '../../../../client/buttons/base';
import ContestStatus from './ContestStatus';
import EntryManager from './EntryManager';
import Timeline from './Timeline';

import { ENTRY, IDEA, IN_PROGRESS, PRE_REGISTRATION } from '../../constants.js';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import styles from './side_panel.css';

/**
 * Helpers
 */
const _registrationOpen = (status) => [PRE_REGISTRATION, IN_PROGRESS].includes(status);
const _shouldShowTimeline = ({ timelineEvents }) => timelineEvents.challengeEvents.length > 0;
const _shouldShowUnregisterButton = ({ contestStatus }) => _registrationOpen(contestStatus.status);

/**
 * Views
 */
const _getEntrySection = (props) => (
  props.initialized ? _getEntryManagerView(props) : null
);

const _getEntryManagerView = (props) => (
  <EntryManager
    allowMultipleEntries={props.allowMultipleEntries}
    canEditSubmission={props.canEditSubmission}
    contestStatus={props.contestStatus.status}
    createEntry={props.createEntry}
    faqPath={props.faqPath}
    getUserProjects={props.getUserProjects}
    initialized={props.initialized}
    isBusy={props.isBusy}
    newProjectPath={props.newProjectPath}
    resubmitEntry={props.resubmitEntry}
    serverErrors={props.serverErrors}
    user={props.user}
    withdrawEntryOrIdea={props.withdrawEntryOrIdea}
  />
);

const _getRegisteredView = (props) => (
  <div>
    <h5 className={`${styles.rowHeader} ${typography.h5}`}>Registration</h5>
    <div className={styles.registrationRow}>
      <div>You are registered!</div>
      {_shouldShowUnregisterButton(props)
      && (
        <Button
          colorStyle="secondary"
          disabled={props.isBusy}
          onClick={props.deleteRegistration}
          size="sm"
        >
          Unregister
        </Button>
      )}
    </div>
    <div className={layout.marginTop15}>
      <a className={`${typography.link} ${typography.bodyM}`} href={props.editNotificationsPath}>
        Edit notification preferences
      </a>
    </div>
  </div>
);

const _getRegistrationView = (props) => {
  const innerView = _getRegistrationInnerView(props);

  return (
    <TransitionGroup appear={true}>
      {!!innerView
      && (
        <MaxHeightTransition maxHeight={75}>
          <div className={styles.rowContainer}>
            {innerView}
          </div>
        </MaxHeightTransition>
      )}
    </TransitionGroup>
  );
};

const _getRegistrationInnerView = (props) => {
  const shouldShowRegisteredViewV2Layout = props.user.isRegistered && _shouldShowUnregisterButton(props);

  if (!props.initialized) return null;
  if (shouldShowRegisteredViewV2Layout) return _getRegisteredView(props);

  return null;
};

/**
 * Main Component
 */
const ContestBriefSidePanel = (props) => (
  <div>
    {_getEntrySection(props)}
    {_getRegistrationView(props)}
    <ContestStatus {...props.contestStatus} />
    {_shouldShowTimeline(props) && <Timeline {...props.timelineEvents} />}
  </div>
);

ContestBriefSidePanel.propTypes = {
  allowMultipleEntries: PropTypes.bool.isRequired,
  canEditSubmission: PropTypes.shape({
    [ENTRY]: PropTypes.bool.isRequired,
    [IDEA]: PropTypes.bool.isRequired,
  }).isRequired,
  contestStatus: PropTypes.shape({
    date: PropTypes.string,
    status: PropTypes.string,
    text: PropTypes.arrayOf(PropTypes.string).isRequired,
    timeRemaining: PropTypes.string,
  }).isRequired,
  createEntry: PropTypes.func.isRequired,
  createRegistration: PropTypes.func.isRequired,
  deleteRegistration: PropTypes.func.isRequired,
  editNotificationsPath: PropTypes.string.isRequired,
  faqPath: PropTypes.string.isRequired,
  getUserProjects: PropTypes.func.isRequired,
  initialized: PropTypes.bool.isRequired,
  isBusy: PropTypes.bool.isRequired,
  newProjectPath: PropTypes.string.isRequired,
  resubmitEntry: PropTypes.func.isRequired,
  serverErrors: PropTypes.shape({
    createEntry: PropTypes.string,
    deleteEntry: PropTypes.string,
  }),
  timelineEvents: PropTypes.shape({
    challengeEvents: PropTypes.arrayOf(PropTypes.shape({
      date: PropTypes.string.isRequired,
      headerText: PropTypes.string.isRequired,
      isPast: PropTypes.bool.isRequired,
      ms: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    nowMs: PropTypes.number.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    entries: PropTypes.arrayOf(PropTypes.shape({
      can_be_resubmitted: PropTypes.bool,
      id: PropTypes.number,
      moderator_feedback: PropTypes.string,
      project: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        url: PropTypes.string,
      }),
      status: PropTypes.string,
      updated_at: PropTypes.string,
    })).isRequired,
    ideas: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      status: PropTypes.string,
      updated_at: PropTypes.string,
      url: PropTypes.string,
    })).isRequired,
    isRegistered: PropTypes.bool.isRequired,
    projects: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })),
    registration: PropTypes.shape({
      challenge: PropTypes.shape({
        activate_email_sharing: PropTypes.bool,
        after_registration_tweet: PropTypes.string,
        email_sharing_label: PropTypes.string,
        facebook_url: PropTypes.string,
        reddit_url: PropTypes.string,
        twitter_url: PropTypes.string,
      }),
    }),
  }).isRequired,
  withdrawEntryOrIdea: PropTypes.func.isRequired,
};

ContestBriefSidePanel.defaultProps = { serverErrors: null };

export default ContestBriefSidePanel;
