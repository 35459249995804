import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import ConfirmEmailMessage from '../../../client/reusable_components/ConfirmEmailMessage';
import EventCardHorizontalSmall from '../../../client/cards/event_card/EventCardHorizontalSmall.js';
import EventCardSponsoredLarge from '../../../client/cards/event_card/EventCardSponsoredLarge.js';
import EventCardSponsoredSmall from '../../../client/cards/event_card/EventCardSponsoredSmall.js';
import EventCardText from '../../../client/cards/event_card/EventCardText';
import GridList from '../../../client/wrappers/grid_list';
import LocalVirtualEventsList from './LocalVirtualEventsList';

import SeoHandler from '../../../services/seo_handler';
import currentUserService from '../../../services/current_user';
import errorHandler from '../../../services/error_handler';
import getGridClassNames from '../../../client/projects/projects_list/getGridClassNames';
import normalizedToQueryString from '../../../services/algolia/converters/normalizedToQueryString';
import seoConfig from '../seoConfig';
import { fireViewAllSectionClickAnalytics } from '../../../services/keen/mainServiceOperators';
import { getFormattedLocation } from '../../../services/country_data';
import { getSortEnum, getTimeFilterEnum } from '../../../graphql/events/enums';
import { graphQuery } from '../../../requests/graphql';
import { isBlank, isSignedInUser } from '../../../utility/types';
import { summonGlobalDialog, summonLoginPanel } from '../../../utility/dispatchers';

import buttonStyles from '../../../styles/global_ui/buttons.css';
import gridStyles from '../../../styles/global_ui/grid.css';
import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';
import styles from './events_home_page.css';

const UPCOMING_LIST_MAX_COUNT = 9;

const DEFAULT_UPCOMING_QUERY_ARGS = {
  by_approved: true,
  by_sponsored: false,
  by_time: getTimeFilterEnum('upcoming'),
  sort: getSortEnum('most_recent', 1),
};

const LOCAL_AND_VIRTUAL_KEYS = ['local_and_virtual', 'local', 'virtual'];

const LIST_CONFIG = {
  past: {
    title: 'Past events',
    card: EventCardText,
  },
  upcoming: {
    title: 'All other events',
    card: EventCardHorizontalSmall,
  },
  virtual: { title: 'Virtual events' },
};

const seeAllLinkMobileStyles = `${layout.hiddenSmallUp} ${layout.flexJustifyCenter} ${layout.marginTop30}`;

class EventsHomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      initialized: false,
      events: this._initEventsFromProps(props.init_events),
    };

    this.getSeeAllLink = this.getSeeAllLink.bind(this);
    this.handleAddEventClick = this.handleAddEventClick.bind(this);

    this.seoHandler = new SeoHandler({ config: seoConfig });
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this._init();
  }

  /**
   * Initializers
   */
  _init() {
    return Promise.all([currentUserService.getStoreAsync(), graphQuery({ t: 'events_home_custom' })])
      .then((results) => this._updateEventLists(results))
      .catch((err) => {
        this.setState({ initialized: true });
        errorHandler('EventsHomePage _init', err);
      });
  }

  _initEventsFromProps(events) {
    return {
      sponsored: events.home_config.sponsored,
      past: events.past,
      upcoming: events.upcoming,
    };
  }

  _updateEventLists([currentUser, { events_local_and_virtual }]) {
    this.setState((prevState) => ({
      currentUser,
      events: { ...prevState.events, ...events_local_and_virtual },
      initialized: true,
    }));

    this._updateUpcomingList(events_local_and_virtual);
  }

  _updateUpcomingList(localAndVirtualEvents) {
    const { blacklist, uniqueUpcomingEvents } = this._getDataForUpcomingListUpdate(localAndVirtualEvents);
    const additionalEventsCount = UPCOMING_LIST_MAX_COUNT - uniqueUpcomingEvents.length;

    if (additionalEventsCount < 1) return;

    return graphQuery({ t: 'events' }, { ...DEFAULT_UPCOMING_QUERY_ARGS, id_blacklist: blacklist, limit: additionalEventsCount })
      .then(({ events: moreUpcomingEvents }) => this.setState((prevState) => ({
        events: {
          ...prevState.events,
          upcoming: [...uniqueUpcomingEvents, ...moreUpcomingEvents],
        },
      })))
      .catch((err) => {
        errorHandler('EventsHomePage _updateUpcomingList', err);
      });
  }

  /**
   * Methods
   */
  handleAddEventClick(e) {
    if (!isSignedInUser(this.state.currentUser)) {
      e.preventDefault();

      return summonLoginPanel({ detail: { state: { currentPanel: 'signup' }, source: 'events_home' } });
    }

    if (!this.state.currentUser.isConfirmed) {
      e.preventDefault();

      return summonGlobalDialog({ getChildren: () => (<ConfirmEmailMessage actionText="create an event" />) });
    }
  }

  /**
   * Helpers
   */
  _getDataForUpcomingListUpdate(localAndVirtualEvents) {
    const { local_and_virtual, local, virtual } = localAndVirtualEvents;

    const localAndVirtualEventIds = [local_and_virtual, local, virtual].flat().map((event) => event.id);
    const uniqueUpcomingEvents = this.state.events.upcoming.filter((event) => !localAndVirtualEventIds.includes(event.id));
    const uniqueUpcomingEventIds = uniqueUpcomingEvents.map((event) => event.id);

    return { blacklist: [...localAndVirtualEventIds, ...uniqueUpcomingEventIds], uniqueUpcomingEvents };
  }

  _getListTitle(key) {
    switch (key) {
      case 'local_and_virtual':
        return `Events near ${getFormattedLocation(this.state.events.location)} & virtual events`;
      case 'local':
        return `Events near ${getFormattedLocation(this.state.events.location)}`;
      default:
        return LIST_CONFIG[key].title;
    }
  }

  _getLocationSearchFilter() {
    const location = this.state.events.location;
    if (!location) return {};

    return {
      city: location.city,
      state: location.state,
      countryCode: location.country_iso2,
      distance: 5,
      geoloc: {
        lat: location.latitude,
        lng: location.longitude,
      },
    };
  }

  _getSearchFiltersForViewAllLink(key) {
    switch (key) {
      case 'local':
        return { date: 'upcoming', location: this._getLocationSearchFilter(), sort: 'earliest_first' };
      case 'local_and_virtual':
        // NOTE: this doesn't really map well to any set of available search filters. using "upcoming" for now
        return { date: 'upcoming', sort: 'earliest_first' };
      case 'past':
        return { date: 'past' };
      case 'upcoming':
        return { date: 'upcoming', sort: 'earliest_first' };
      case 'virtual':
        return { attendance_type: 'virtual', date: 'upcoming', sort: 'earliest_first' };
      default:
        return {};
    }
  }

  _getViewAllHref(key) {
    const queryMap = {
      filters: this._getSearchFiltersForViewAllLink(key),
      index: 'events',
      query: '',
    };

    return `${this.props.search_path}${normalizedToQueryString(queryMap)}`;
  }

  /**
   * Views
   */
  _getList(key) {
    const records = this.state.events[key];
    if (isBlank(records)) return;

    return (
      <div key={key} className={layout.marginTop60}>
        <div className={`${layout.flexJustifySpaceBetween} ${layout.marginBottom22}`}>
          <p className={typography.h4}>
            {this._getListTitle(key)}
          </p>
          {this.getSeeAllLink(key)}
        </div>
        <GridList
          ItemComponent={LIST_CONFIG[key].card}
          className={`${gridStyles.guttersH30_sm} ${gridStyles.guttersV30_sm}`}
          gutterSize={15}
          maxCols={3}
          records={records}
        />
        {this.getSeeAllLink(key, false)}
      </div>
    );
  }

  _getLocalAndVirtualSection() {
    return this.state.initialized
      ? LOCAL_AND_VIRTUAL_KEYS.map((key) => this._getLocalOrVirtualList(key))
      : (<LocalVirtualEventsList isDummyList={true} />);
  }

  _getLocalOrVirtualList(key) {
    if (isBlank(this.state.events[key])) return;

    return (
      <LocalVirtualEventsList
        key={key}
        eventType={key}
        events={this.state.events[key]}
        getSeeAllLink={this.getSeeAllLink}
        title={this._getListTitle(key)}
      />
    );
  }

  getSeeAllLink(key, isDesktopView = true) {
    const href = this._getViewAllHref(key);

    return (
      <a
        className={isDesktopView ? layout.hiddenSmallDown : seeAllLinkMobileStyles}
        href={href}
        onClick={(e) => {
          fireViewAllSectionClickAnalytics({
            delayRedirect: true,
            e,
            item: { type: 'view_all_link' },
            section: { key: 'event', value: key },
            url: href,
          });
        }}
      >
        <p className={`${typography.bodyM} ${typography.linkBlue} ${typography.bold}`}>
          {`See all ${key.split('_').join(' ')} events`}
        </p>
      </a>
    );
  }

  _getSingleEventSponsoredList(event) {
    return (
      <Fragment>
        <EventCardSponsoredLarge className={layout.hiddenSmallDown} imageRight={true} item={event} />
        <EventCardSponsoredSmall className={layout.hiddenSmallUp} item={event} />
      </Fragment>
    );
  }

  _getSponsoredList(events) {
    const gridClassNames = getGridClassNames(false, events.length, 30);

    return (
      <div className={gridClassNames.grid}>
        {events.map((event, i) => (
          <EventCardSponsoredSmall
            key={event.id}
            className={gridClassNames.cell}
            item={event}
          />
        ))}
      </div>
    );
  }

  render() {
    const [firstSponsoredEvent, ...remainingSponsoredEvents] = this.state.events.sponsored || [];

    return (
      <Fragment>
        <div className={`${layout.container} ${layout.paddingBottom0}`}>
          <div className={layout.wrapper1170}>
            <header className={`${layout.flexJustifySpaceBetween} ${layout.flexCenterItems}`}>
              <h1 className={typography.h1}>Events</h1>
              <a
                className={`${buttonStyles.sm} ${layout.flex00Auto} ${this.state.initialized ? '' : utilStyles.disabled}`}
                href={this.props.new_event_path}
                onClick={this.handleAddEventClick}
              >
                Add an event
              </a>
            </header>

            {firstSponsoredEvent
            && (
              <div className={layout.marginTop60}>
                <EventCardSponsoredLarge item={firstSponsoredEvent} />
              </div>
            )}

            {this._getLocalAndVirtualSection()}

            {remainingSponsoredEvents.length > 0
            && (
              <div className={layout.marginTop60}>
                {remainingSponsoredEvents.length === 1
                  ? this._getSingleEventSponsoredList(remainingSponsoredEvents[0])
                  : this._getSponsoredList(remainingSponsoredEvents)}
              </div>
            )}

            {this._getList('upcoming')}
          </div>
        </div>

        <div className={`${layout.container} ${layout.paddingTop0} ${layout.paddingBottom60} ${layout.marginTop45} ${styles.fadeBackground}`}>
          <div className={layout.wrapper1170}>
            {this._getList('past')}
          </div>
        </div>
      </Fragment>
    );
  }
}

EventsHomePage.propTypes = {
  init_events: PropTypes.shape({
    home_config: PropTypes.shape({ sponsored: PropTypes.array }),
    past: PropTypes.array,
    upcoming: PropTypes.array,
  }).isRequired,
  new_event_path: PropTypes.string.isRequired,
  search_path: PropTypes.string.isRequired,
};

export default EventsHomePage;
