import React from 'react';

import urlService from '../../../../services/url_service';
import { DATE_AT_TIME_FORMAT, timeIsPast, timestampToPrettyDate } from '../../../../utility/time';
import { isObjectWithLength } from '../../../../utility/types';

import typography from '../../../../styles/global_ui/typography.css';

const challengeText = {
  // completed: (name, path, onClick) => (<span>The challenge {createLink(path, name, onClick)} is now closed for submissions. Time to award prizes!</span>),
  ending_soon: (name, path, onClick) => (
    <span>
      {createLink(path, name, onClick)}
      {' '}
      is ending soon, time to submit your project!
    </span>
  ),
  free_hardware_complete: (name, path, onClick) => (
    <span>
      Free hardware winners for
      {createLink(path, name, onClick)}
      {' '}
      have been selected!
    </span>
  ),
  free_hardware_end_soon: (name, path, onClick) => (
    <span>
      Free hardware applications for
      {createLink(path, name, onClick)}
      {' '}
      are closing soon, time to submit yours!
    </span>
  ),
  half_way: (name, path, onClick) => (
    <span>
      {createLink(path, name, onClick)}
      {' '}
      is half-way through, how is your submission going?
    </span>
  ),
  judged: (name, path, onClick) => (
    <span>
      The results for
      {createLink(path, name, onClick)}
      {' '}
      are out!
    </span>
  ),
  // launched_pre_contest: (name, path, onClick) => (<span>{createLink(path, name, onClick)} is now accepting ideas!</span>),
  launched_contest: (name, path, onClick) => (
    <span>
      {createLink(path, name, onClick)}
      {' '}
      is now open for submissions!
    </span>
  ),
  // pre_contest_awarded: (name, path, onClick) => (<span>Winners for the pre-contest of {createLink(path, name, onClick)} have been announced.</span>),
  // pre_contest_ending_soon: (name, path, onClick) => (<span>The precontest of {createLink(path, name, onClick)} is ending soon, time to submit your idea!</span>),
  // pre_contest_winners: (name, path, onClick) => (<span>Your idea has been selected as a winner for the pre-contest of {createLink(path, name, onClick)}. Congrats!</span>),
};

// Takes the "relations" field from comments/posts and tries to find current user id
function isCurrentUserMentioned(relations, currentUserId) {
  if (!isObjectWithLength(relations)) return false;

  return Object.keys(relations).some((key) => relations[key].id === currentUserId && relations[key].type === 'user');
}

function getCommentActionText({ currentUserId, parent_comment_author_id, relations }) {
  if (isCurrentUserMentioned(relations, currentUserId)) {
    return ` mentioned you in a${parent_comment_author_id === currentUserId ? ' reply to your' : ' '} comment on `;
  } else {
    return parent_comment_author_id === currentUserId ? ' replied to your comment on ' : ' commented on ';
  }
}

function getCommentLinkText({ commentable_name, commentable_type, currentUserId, post_author_id, post_author_name }) {
  switch (commentable_type) {
    case 'Project':
      return commentable_name;
    case 'FeedPost':
      return post_author_id === currentUserId ? 'your post' : `${post_author_name}'s post`;
    default:
      return '';
  }
}

function commentLink({ commentable_name, commentable_path, commentable_type, currentUserId, post_author_id, post_author_name }, onClick) {
  const body = getCommentLinkText({ commentable_name, commentable_type, currentUserId, post_author_id, post_author_name });

  return createLink(commentable_path, body, onClick);
}

function userLink(path, name, onClick) {
  return name && path ? createLink(path, name, onClick) : 'Someone';
}

function createLink(path, body, onClick) {
  return (
    <a className={`${typography.link} ${typography.bold}`} href={urlService.url(path)} onClick={onClick}>{body}</a>
  );
}

// TODO: 11-2022
// :approved is deprecated
function projectMessage(event, { made_public_at, name, path }, onClick) {
  return (
    <span>
      {createLink(path, name, onClick)}
      {' has been approved and will show on the home page and related platform pages '}
      {timeIsPast(made_public_at) ? 'immediately' : `on ${timestampToPrettyDate(made_public_at, DATE_AT_TIME_FORMAT)}.`}
    </span>
  );
}

// TODO: Remove deprecated Classes.
const builders = {
  Challenge: (event, { name, path }, onClick) => {
    const getText = challengeText[event];

    return typeof getText === 'function' ? getText(name, path, onClick) : null;
  },
  // TODO: 11-2022
  // 1) :marked_incomplete is not being handled here.
  // 2) :approved is deprecated
  ChallengeEntry: (event, { name, path }, onClick) => (
    <span>
      {`${event === 'awarded' ? 'Congratulations! ' : ''}Your entry for `}
      {createLink(path, name, onClick)}
      {`${event === 'awarded'
        ? ' has been awarded a prize. Follow instructions sent to your email to claim it.'
        : ' has been approved.'}`}
    </span>
  ),
  // TODO: 11-2022
  // :awarded is deprecated
  ChallengeIdea: (event, { comm, challenge_name, challenge_path, idea_name }, onClick) => (
    <span>
      {`Your idea ${idea_name} for `}
      {createLink(challenge_path, challenge_name, onClick)}
      {`${event === 'approved' ? ' has been approved.' : ' has won.'}`}
    </span>
  ),
  // TODO: 11-2002
  // :mention, :mentioned are deprecated
  Comment: (event, { author_name, author_path, commentable_id, commentable_name, commentable_path, commentable_type, origin_name, parent_comment_author_id, post_author_id, post_author_name, relations }, onClick, currentUserId) => (
    <span>
      {userLink(author_path, author_name, onClick)}
      {getCommentActionText({ currentUserId, parent_comment_author_id, relations })}
      {commentLink({ commentable_name, commentable_path, commentable_type, currentUserId, post_author_id, post_author_name }, onClick)}
      {origin_name ? ` in ${origin_name}` : '.'}
    </span>
  ),
  FeedPost: (event, { author_name, author_path, post_path, origin_name, relations }, onClick, currentUserId) => (
    <span>
      {userLink(author_path, author_name, onClick)}
      {isCurrentUserMentioned(relations, currentUserId) ? ' mentioned you in a ' : ' created a '}
      {createLink(post_path, 'new post', onClick)}
      {origin_name ? ` in ${origin_name}.` : '.'}
    </span>
  ),
  FollowRelation: (event, { followed_id, followed_name, followed_path, follower_name, follower_path }, onClick, currentUserId) => (
    <span>
      {createLink(follower_path, follower_name, onClick)}
      {' followed '}
      {followed_id === currentUserId ? 'you' : createLink(followed_path, followed_name, onClick)}
      .
    </span>
  ),
  // GlobalAnnouncement: (event, {body_elements=[]}, onClick) => (
  //   <span>
  //     {body_elements.map((elem, i) =>
  //       <span key={i}>
  //         {elem.type === 'link' ? createLink(elem.href, elem.text, onClick) : elem.text}
  //       </span>)
  //     }
  //   </span>
  // ),
  Member: (event, { group_name, group_path, user_name, user_path }, onClick, currentUserId) => (
    <span>
      {createLink(user_path, user_name, onClick)}
      {event === 'accepted' ? ' has accepted your invitation to join ' : ' has invited you to join '}
      {createLink(group_path, group_name, onClick)}
      .
    </span>
  ),
  Project: (event, { made_public_at, name, path }, onClick) => (projectMessage(event, { made_public_at, name, path }, onClick)),
  Receipt: (event, { user_name, user_path, conversation_path }, onClick) => (
    <span>
      {createLink(user_path, user_name, onClick)}
      {' has sent you '}
      {createLink(conversation_path, 'a message', onClick)}
      .
    </span>
  ),
  Respect: (event, { user_name, user_path, respectable_name, respectable_path, respectable_type }, onClick) => {
    const body = respectable_type === 'Comment'
      ? 'one of your comments'
      : respectable_type === 'FeedPost'
        ? 'one of your posts'
        : respectable_name;

    return (
      <span>
        {createLink(user_path, user_name, onClick)}
        {' respected '}
        {createLink(respectable_path, body, onClick)}
        .
      </span>
    );
  },
  User: (event, { name, path }, onClick) => (
    <span>
      {createLink(path, name, onClick)}
      {' has accepted your invitation to join Hackster.'}
    </span>
  ),
};

// eslint-disable-next-line react/display-name
export default function({ type, event, fields, currentUserId, onClick }) {
  return builders[type] ? builders[type](event, fields, onClick, currentUserId) : null;
}
