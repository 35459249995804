import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { summonGlobalMessenger } from '../../utility/dispatchers';
import CheckboxCard from '../../client/form_components/checkboxes/card';
import LazyImage from '../../client/reusable_components/LazyImage';

import { graphMutate } from '../../requests/graphql';
import errorHandler from '../../services/error_handler';

import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';
import styles from './activities.css';

const IMAGE_HEIGHT = 95;

const ACTIVITY_LABEL_MAP = {
  browse_projects: {
    label: 'Browse projects',
    imageUrl: 'https://hackster.imgix.net/static/icons/browse.png',
    ratio: 1.14,
    maxHeight: IMAGE_HEIGHT,
    subText:
      'Get inspired by projects from a variety of technologies, interests and skill levels.',
  },
  news: {
    label: 'Read the news',
    imageUrl: 'https://hackster.imgix.net/static/icons/news.png',
    ratio: 1.16,
    maxHeight: IMAGE_HEIGHT,
    subText: 'Keep up with the latest hardware news, from major launches to niche industry happenings.',

  },
  explore_products: {
    label: 'Explore products',
    imageUrl: 'https://hackster.imgix.net/static/icons/chip.png',
    ratio: 1,
    maxHeight: IMAGE_HEIGHT,
    subText: 'Learn about new and timeless products, and discover innovative ways to utilize them.',

  },
  publish_projects: {
    label: 'Publish projects',
    imageUrl: 'https://hackster.imgix.net/static/icons/publish.png',
    ratio: 0.97,
    maxHeight: IMAGE_HEIGHT,
    subText: 'Showcase your work and inspire others by publishing your projects.',

  },
  contests: {
    label: 'Enter contests',
    imageUrl: 'https://hackster.imgix.net/static/icons/trophy.png',
    ratio: 1.01,
    maxHeight: IMAGE_HEIGHT,
    subText: 'Enter in exciting challenges that push the boundaries of hardware innovation and win prizes. ',

  },
  events: {
    label: 'Attend events',
    imageUrl: 'https://hackster.imgix.net/static/icons/calendar.png',
    ratio: 1.01,
    maxHeight: IMAGE_HEIGHT,
    subText: 'Check out upcoming events in the tech community both virtual and in person.',

  },
  watch_videos: {
    label: 'Watch videos',
    imageUrl: 'https://hackster.imgix.net/static/icons/watch_videos.png',
    ratio: 1.35,
    maxHeight: IMAGE_HEIGHT,
    subText:
      'Immerse yourself in tech knowledge with tech spotlights and hardware unboxing videos.',

  },
  follow_companies: {
    label: 'Follow companies',
    subText: 'Stay up to date with your favorite tech companies.',
    imageUrl: [
      'https://hackster.imgix.net/static/marketing/business-logos/arduino.png',
      'https://hackster.imgix.net/static/marketing/business-logos/node-mcu.png',
      'https://hackster.imgix.net/static/marketing/business-logos/raspberry-pi.png',
      'https://hackster.imgix.net/static/marketing/business-logos/df-robot.png',
      'https://hackster.imgix.net/static/marketing/business-logos/ubuntu.png',
      'https://hackster.imgix.net/static/marketing/business-logos/amd-small.png',
    ],
  },
};

const Card = ({ checked, handleUpdate, option }) => {
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onChange = async (option) => {
    setIsLoading(true);
    await handleUpdate(option);
    setIsLoading(false);
  };

  const hasImageArray = Array.isArray(option.imageUrl);

  const handleEnter = () => setIsActive(true);
  const handleLeave = () => setIsActive(false);

  return (
    <div
      className={`${layout.flex} ${layout.fullWidth}`}
    >
      <CheckboxCard
        checkboxStyles={`${layout.fullHeight} ${layout.fullWidth} ${layout.flexCenterItems} ${typography.textCenter} ${styles.card}`}
        isChecked={checked}
        isDisabled={isLoading}
        name={option.label}
        onBlur={handleLeave}
        onChange={onChange}
        onFocus={handleEnter}
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
        value={option.value}
        wrapperStyles={`${layout.fullWidth}`}
      >
        <h2 className={`${typography.h3Responsive} ${typography.charcoal} ${layout.marginTop5} ${hasImageArray ? styles.platformHeader : styles.header}`}>{option.label}</h2>
        <div className={`${layout.flexColumnTopCenter}`}>
          {hasImageArray
            ? (
              <div className={`${styles.platformsWrapper}`}>
                {option.imageUrl.map((image) => (
                  <LazyImage
                    key={image}
                    alt={option.label}
                    classList={{ root: `${isActive || checked ? styles.platformWrapperActive : ''} ${styles.imageWrapper}` }}
                    fit="fill"
                    ratio="1:1"
                    src={image}
                    width={47}
                  />
                ))}
              </div>
              )
            : (
              <img
                alt={option.label}
                className={`${isActive || checked ? styles.imageWrapperActive : ''} ${styles.imageWrapper}`}
                height={option.maxHeight}
                src={option.imageUrl}
                width={option.maxHeight * option.ratio}
              />
              )}
          <p className={`${typography.bodyS} ${styles.subText} ${isActive || checked ? styles.show : ''}`}>{option.subText}</p>
        </div>
      </CheckboxCard>
    </div>
  );
};

const Activities = ({
  activities,
  options,
  setSelectedActivities,
}) => {
  const formattedOpts = options.map((key) => ({
    ...ACTIVITY_LABEL_MAP[key],
    value: key,
  }));

  const handleUpdate = ({ value, checked }) => {
    const updatedActivities = checked
      ? [...activities, value]
      : activities.filter((activity) => value !== activity);

    setSelectedActivities(updatedActivities);

    return graphMutate({ t: 'update_user_activities' }, { [value]: checked })
      .catch((err) => {
        if (err?.status && err.status >= 400) {
          setSelectedActivities(activities);
          summonGlobalMessenger({ msg: 'Oops, something went wrong. Try again later.', type: 'error' });
        }

        errorHandler('updateActivities Error: ', err);
      });
  };

  useLayoutEffect(() => {
    // scroll to top
    window?.scrollTo({ top: 0 });
  }, []);

  return (
    <main className={typography.textCenter}>
      <form
        className={`${styles.grid}`}
      >
        {formattedOpts.map((option) => (
          <Card
            key={option.value}
            checked={activities.includes(option.value)}
            handleUpdate={handleUpdate}
            option={option}
          />

        ))}
      </form>
    </main>
  );
};

Activities.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedActivities: PropTypes.func.isRequired,
};

Activities.defaultProps = { activities: [] };

export default Activities;
