import React from 'react';
import PropTypes from 'prop-types';

import SmallProjectCard from '../small_project_card';
import UserCard from '../user_card';

import cardStyles from '../cards.css';
import typography from '../../../styles/global_ui/typography.css';
import layoutStyles from '../../../styles/global_ui/layout.css';
import styles from './contest_idea_card.css';

const IDEA_WINNER_STATUS = 'APPROVED';
const JUDGED_STATUS = 'JUDGED';
const PROJECT_CLOSED_STATES = ['JUDGING', 'JUDGED'];

const cleanHtml = (html) => html.replace(/(<[^>]+>)/g, '').replace(/&nbsp;/g, ' ');

const getBody = (item) => {
  const fullBody = item.old_html_description ? cleanHtml(item.old_html_description) : item.application.problem;
  return fullBody.length > 143 ? `${fullBody.slice(0, 140)}...` : fullBody;
};

const ProjectPlaceHolder = ({ challengeState }) => {
  const label = PROJECT_CLOSED_STATES.includes(challengeState) ? 'No project was submitted' : 'No project submitted yet';

  return (
    <div className={styles.placeholderOuter}>
      <div className={styles.placeholderInner}>
        <div className={`${typography.bodyS} ${typography.pebble}`}>{label}</div>
      </div>
    </div>
  );
};

const ContestIdeaCard = ({ classList, challengeState, item, onClick, showProjectsBeforeJudged, winnersAnnounced }) => {
  const showWinner = winnersAnnounced && item.status === IDEA_WINNER_STATUS;

  return (
    <div className={`${cardStyles.wrapper} ${classList.wrapper}`}>
      <div className={cardStyles.cardWithBorder}>
        <div className={styles.body}>
          {showWinner
          && <div className={`${typography.h5} ${cardStyles.corner}`}>Winner</div>}
          <a className={styles.linkWrapper} href={item.url} onClick={(e) => onClick(e, item)}>
            <p className={`${typography.bodyL} ${typography.bold} ${typography.link} ${showWinner ? layoutStyles.marginRight15 : ''} ${styles.title}`}>
              {item.name}
            </p>
            <p className={`${layoutStyles.marginTop5} ${typography.bodyM}`}>
              {getBody(item)}
            </p>
            <div className={cardStyles.spacer} />
          </a>
        </div>
        <div className={styles.user}>
          <UserCard avatarSize={30} titleSize="L" user={item.user} />
        </div>
        {((showProjectsBeforeJudged && winnersAnnounced) || challengeState === JUDGED_STATUS)
        && (
          <div className={styles.project}>
            <h5 className={`${typography.h5} ${styles.projectHeader}`}>contest submission</h5>
            {item.project
              ? (
                <SmallProjectCard
                  lazy={true}
                  location="challenge_idea"
                  project={item.project}
                />
                )
              : <ProjectPlaceHolder challengeState={challengeState} />}
          </div>
        )}
      </div>
    </div>
  );
};

ContestIdeaCard.propTypes = {
  classList: PropTypes.shape({ wrapper: PropTypes.string }),
  item: PropTypes.shape({
    application: PropTypes.shape({
      build: PropTypes.string,
      components: PropTypes.string,
      problem: PropTypes.string,
      solution: PropTypes.string,
    }),
    created_at: PropTypes.string.isRequired,
    current_user_permissions: PropTypes.shape({
      admin: PropTypes.bool.isRequired,
      edit: PropTypes.bool.isRequired,
    }).isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    old_html_description: PropTypes.string,
    project: PropTypes.shape({
      cover_image_url: PropTypes.string,
      hid: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
    user: PropTypes.shape({
      avatar_url: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
  showProjectsBeforeJudged: PropTypes.bool,
  winnersAnnounced: PropTypes.bool,
};

ContestIdeaCard.defaultProps = {
  classList: { wrapper: '' },
  onClick: () => {},
  showProjectsBeforeJudged: false,
  winnersAnnounced: false,
};

export default ContestIdeaCard;
