import React from 'react';
import PropTypes from 'prop-types';
import * as Dialog from '@radix-ui/react-dialog';

import Icon from '../../icon';

import button from '../../../styles/global_ui/buttons';
import layout from '../../../styles/global_ui/layout.css';
import util from '../../../styles/global_ui/util.css';
import styles from './dialog.css';

const DialogRadix = ({
  asModal,
  children,
  classList,
  defaultOpen,
  hasClose,
  hasOverlay,
  id,
  onInteractOutside,
  onOpenChange,
  open,
  testId,
  title,
  trigger,
}) => (
  <Dialog.Root defaultOpen={defaultOpen} modal={asModal} onOpenChange={onOpenChange} open={open}>
    {trigger && <Dialog.Trigger className={`${classList?.trigger}`}>{trigger}</Dialog.Trigger>}
    <Dialog.Portal>
      {hasOverlay && <Dialog.Overlay />}
      <Dialog.Content
        aria-label={title}
        className={`${util.borderRadiusLarge} ${util.border} ${util.boxShadow} ${styles.dialogContent} ${classList?.container}`}
        data-testid={testId}
        id={id}
        onInteractOutside={onInteractOutside}
      >
        {hasClose && (
          <Dialog.Close aria-label="close" className={`${layout.inlineFlex} ${util.posAbsolute} ${layout.borderNone} ${util.borderRadiusCircle} ${layout.flexCenterCenter} ${button.unset} ${button.white} ${styles.dialogClose}`}>
            <Icon name="close" size={12} />
          </Dialog.Close>
        )}
        {children}
      </Dialog.Content>
    </Dialog.Portal>
  </Dialog.Root>
)
;

export default DialogRadix;

DialogRadix.propTypes = {
  asModal: PropTypes.bool,
  classList: PropTypes.shape({
    container: PropTypes.string,
    trigger: PropTypes.string,
  }),
  hasClose: PropTypes.bool,
  testId: PropTypes.string,
  title: PropTypes.string.isRequired, // for accessibility
  trigger: PropTypes.node, //* trigger from Radix renders a button by default. This prop will likely need be a span or icon component.
};
