import React from 'react';

const HacksterPro = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 86 34"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M68.96 0H17.21C12.49 0 8.22 1.95 5.15 5.08 1.99 8.04 0 12.24 0 16.91s1.99 8.86 5.14 11.82c3.07 3.13 7.34 5.08 12.06 5.08h51.75c9.32 0 16.91-7.58 16.91-16.91S78.28 0 68.96 0ZM13.99 11.19v.99c0 .21-.17.38-.38.38h-2.62a.38.38 0 0 1-.38-.38v-.06c0-.21.17-.43.37-.48l2.64-.72c.2-.06.37.07.37.28v-.01ZM10.6 24.82v-5.09c0-.21.17-.38.38-.38h2.62c.21 0 .38.17.38.38v4.16c0 .21-.17.43-.37.48l-2.64.72c-.2.06-.37-.07-.37-.28v.01Zm11.25-3.07c0 .21-.17.43-.37.48l-2.64.72c-.2.06-.37-.07-.37-.28v-.99c0-.21.17-.38.38-.38h2.62c.21 0 .38.17.38.38v.07Zm-3.01-1.15c-.2.06-.37-.07-.37-.28v-1.67a.38.38 0 0 0-.38-.38h-7.1a.38.38 0 0 1-.38-.38v-1.94a.38.38 0 0 0-.38-.38H6.82c-.13 0-.24-.11-.24-.24 0-.08.03-.13.07-.17.08-.07.33-.13.54-.18l6.44-1.76c.2-.06.37.07.37.28v1.69c0 .21.17.38.38.38h7.09c.21 0 .38.17.38.38v1.92c0 .21.17.38.38.38h3.44c.13 0 .24.11.24.24 0 .07-.03.13-.07.16-.08.07-.33.12-.54.18l-6.47 1.77h.01Zm-.37-10.63c0-.21.17-.43.37-.48l2.64-.72c.2-.06.37.07.37.28v5.09c0 .21-.17.38-.38.38h-2.62a.38.38 0 0 1-.38-.38V9.97Zm50.49 21.84H22.68c5.76-2.5 9.81-8.24 9.81-14.91S28.45 4.49 22.68 1.99h46.28c8.22 0 14.91 6.69 14.91 14.91 0 8.22-6.69 14.91-14.91 14.91Z"
      fill="#2E9FE6"
    />
    <path
      d="M68.96 31.81H22.68c5.76-2.5 9.81-8.24 9.81-14.91S28.45 4.49 22.68 1.99h46.28c8.22 0 14.91 6.69 14.91 14.91 0 8.22-6.69 14.91-14.91 14.91Z"
      fill="#fff"
    />
    <path
      d="M18.84 20.6c-.2.06-.37-.07-.37-.28v-1.67a.38.38 0 0 0-.38-.38h-7.1a.38.38 0 0 1-.38-.38v-1.94a.38.38 0 0 0-.38-.38H6.82c-.13 0-.24-.11-.24-.24 0-.08.03-.13.07-.17.08-.07.33-.13.54-.18l6.44-1.76c.2-.06.37.07.37.28v1.69c0 .21.17.38.38.38h7.09c.21 0 .38.17.38.38v1.92c0 .21.17.38.38.38h3.44c.13 0 .24.11.24.24 0 .07-.03.13-.07.16-.08.07-.33.12-.54.18l-6.47 1.77h.01ZM18.47 9.97c0-.21.17-.43.37-.48l2.64-.72c.2-.06.37.07.37.28v5.09c0 .21-.17.38-.38.38h-2.62a.38.38 0 0 1-.38-.38V9.97ZM13.99 11.2v.98c0 .21-.17.38-.38.38h-2.62a.38.38 0 0 1-.38-.38v-.06c0-.21.17-.43.37-.48l2.64-.72c.2-.06.37.07.37.28ZM10.6 24.81v-5.08c0-.21.17-.38.38-.38h2.62c.21 0 .38.17.38.38v4.16c0 .21-.17.43-.37.48l-2.64.72c-.2.06-.37-.07-.37-.28ZM21.85 21.75c0 .21-.17.43-.37.48l-2.64.72c-.2.06-.37-.07-.37-.28v-.99c0-.21.17-.38.38-.38h2.62c.21 0 .38.17.38.38v.07Z"
      fill="#fff"
    />
    <path
      d="M36.68 24.17V9.63h6.8c3.16 0 4.88 2.14 4.88 4.69 0 2.55-1.75 4.69-4.88 4.69h-3.71v5.17h-3.1l.01-.01Zm8.53-9.86c0-1.22-.92-1.96-2.14-1.96h-3.29v3.92h3.29c1.22 0 2.14-.76 2.14-1.96ZM58.51 24.17 55.65 19h-2.27v5.17h-3.1V9.63h6.8c3.03 0 4.88 1.98 4.88 4.69 0 2.71-1.61 3.95-3.18 4.32l3.27 5.54H58.5l.01-.01Zm.31-9.86c0-1.22-.94-1.96-2.18-1.96h-3.25v3.92h3.25c1.24 0 2.18-.74 2.18-1.96ZM63.62 16.91c0-4.38 3.21-7.52 7.59-7.52s7.61 3.14 7.61 7.52c0 4.38-3.21 7.52-7.61 7.52s-7.59-3.14-7.59-7.52Zm12.01 0c0-2.73-1.74-4.77-4.43-4.77-2.69 0-4.4 2.05-4.4 4.77 0 2.72 1.72 4.78 4.4 4.78 2.68 0 4.43-2.07 4.43-4.78Z"
      fill="#2E9FE6"
    />
  </svg>
);

export default HacksterPro;
