import React, { Component } from 'react';
import PropTypes from 'prop-types';

import GridList from './../../../../client/wrappers/grid_list';

import buttonStyles from '../../../../styles/global_ui/buttons.css';
import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import styles from './profile.css';

const positionNameStyles = `${typography.h5} ${layout.marginTop15} ${layout.fullWidth}`;
const contestNameStyles = `${typography.bodyM} ${typography.bold} ${layout.marginTop5} ${layout.fullWidth}`;

class Awards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      awardsList: props.awards.slice(0, 4),
      showAll: false,
    };

    this.toggleShowAll = this.toggleShowAll.bind(this);
  }

  toggleShowAll() {
    return this.state.showAll
      ? this.setState({ awardsList: this.props.awards.slice(0, 4), showAll: false })
      : this.setState({ awardsList: this.props.awards, showAll: true });
  }

  _getViewButton() {
    const buttonLabel = this.state.showAll ? 'View less' : 'View all';

    return (
      <button
        className={`${buttonStyles.sm} ${buttonStyles.tertiary} ${buttonStyles.pill} ${layout.flexAlignSelfEnd} ${layout.marginTop10}`}
        onClick={this.toggleShowAll}
      >
        {buttonLabel}
      </button>
    );
  }

  _getAward({ award }) {
    const { category, challenge, icon_urls, position_name } = award;
    const href = challenge.url + `#category-${category.id}`;

    return (
      <a className={styles.award} href={href} rel="noopener noreferrer" target="_blank">
        <div className={styles.awardIconContainer}>
          <img className={styles.awardIcon} src={icon_urls.x1} srcSet={`${icon_urls.x1} 1x, ${icon_urls.x2} 2x`} />
        </div>
        <p className={positionNameStyles}>{position_name}</p>
        <p className={contestNameStyles}>{challenge.name}</p>
      </a>
    );
  }

  _getAwards(awards) {
    return (
      <GridList
        ItemComponent={this._getAward}
        itemKey="award"
        records={awards}
      >
      </GridList>
    );
  }

  render() {
    return (
      <section className={styles.sectionContainer}>
        <header className={styles.sectionHeader}>
          <span className={`${typography.bodyM} ${typography.bold} `}>Awards</span>
          <span className={`${typography.bodyM} ${styles.count}`}>{this.props.awards.length}</span>
        </header>
        {this._getAwards(this.state.awardsList)}
        {this.props.awards.length > 4 && this._getViewButton()}
      </section>
    );
  }
}

Awards.propTypes = {
  awards: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.shape({ id: PropTypes.number }),
    challenge: PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    }),
    icon_urls: PropTypes.shape({
      x1: PropTypes.string,
      x2: PropTypes.string,
    }),
    id: PropTypes.number,
    position_name: PropTypes.string,
  })).isRequired,
};

export default Awards;
